.search-list {
    display: none;
    overflow: auto;
    max-height: 150px;
    box-shadow: 0px 0px 4px 2px lightgrey;
    border-radius: 6px;
    z-index: 1000;
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    &__feedback {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 150px;

        &--error::before {
            font-family: "Font Awesome 5 Free";
            content: "\f071";
            display: inline-block;
            padding-right: 3px;
            vertical-align: middle;
            font-weight: 900;
        }
    }

    &__item {
        padding: 0.3rem;
        transition: background-color 100ms ease-in-out;

        &:hover {
            background-color: lightblue;
            cursor: pointer;
        }

        &:focus,
        &:target,
        &:active {
            background-color: rgb(64, 177, 214);
        }

        &.active {
            color: white;
            background-color: rgb(24, 127, 161);
        }
    }
}

.hint{
    color: rgb(161, 161, 161);
    font-size: small;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: $blue;
    margin-left: auto;
    margin-right: auto;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.auth-notice {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border-radius: 0.2rem;
    padding: 2rem;
    box-shadow: rgb(0, 0, 0, 16%) 0px 1px 4px;

    @media (max-width: 1200px) {
        margin-top: 1rem;
        padding: 1rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}
