$gray-chat: rgba(216, 216, 216, 0.9);

.chat-window {
  --header-height: 65px;
  position: fixed;
  bottom: 0;
  right: 5rem;
  height: 500px;
  width: 350px;
  display: flex;
  flex-direction: column;
  background-color: #999;
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;
  z-index: 100;
  transition: transform 200ms ease-in-out;
  transform: translateY(calc(100% - var(--header-height)));

  @media (width < $mobile) {
    margin-bottom: 73px;
    right: 1rem;
  }

  &.open {
    transform: translateY(0);
    border: 1px solid $gray-chat;

    .chats-screen {
      overflow: auto;
    }

    .chat-window__chevron {
      transform: rotate(0turn);
    }
  }

  &__header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-height);
    background-color: $gray-chat;
    padding: 1rem;
    font-weight: 800;
    cursor: pointer;
    // z-index: 200;
  }

  &__chevron {
    transform: rotate(0.5turn);
    margin-left: auto;
    cursor: pointer;

    &.invert {
      transform: rotate(0turn);
    }
  }

  
  .chats-screen {
    height: 100%;
    background-color: white;
    flex-grow: 1;

    &.empty {
      display: grid;
      place-items: center;
    }

    &__header {
      position: sticky;
      top: 0;
      height: var(--header-height);
      background-color: $gray-chat;
      padding: 1rem;
      font-weight: 800;
      cursor: pointer;
    }

    .chat-preview {
      display: flex;
      align-items: center;
      column-gap: 0.8rem;

      .chat-info{
        flex-grow: 1;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 
          "company time"
          "message notifications";

        &__title {
          grid-area: "company";
          font-weight: 700;
        }
  
        &__message {
          grid-area: "message";
          font-size: 0.9rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }

        &__time {
          grid-area: "time";
          font-size: 0.9rem;
          opacity: 0.9;
        }

        &__notifications{
          grid-area: "notifications";
          display: grid;
          place-items: center;
          margin: auto;
          width: 1.4rem;
          height: 1.4rem;
          font-size: 0.8rem;
          border-radius: 50%;
          background-color: $blue;
          color: white;
          font-style: normal;

          &.hide {
            display: none;
          }
        }
      }
    }
    
  }

  .chat-list {
    background-color: white;
    user-select: none;

    &__entry {
      padding: 1rem;
      background-color: white;
      transition: 200ms ease;
      
      &:hover{
        background-color: rgb(226, 226, 226);
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgb(230, 230, 230);
      }
    }
  }

  .user-logo {
    width: $iconw;
    height: $iconw;
    border-radius: 50%; 
    overflow: hidden;
    flex-shrink: 0;
    border: 1px solid $blue;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .chat {
    display: none;
    
    &.active {
      height: 100%;
      display: flex;
      flex-direction: column;
      position: fixed;
      inset: 0;
      z-index: 10;
    }

    &__header {
      background-color: rgb(230, 230, 230);
      padding: 0.5rem;
      display: flex;
      column-gap: 0.4rem;
      align-items: center;

      .show-profile {
        margin-left: auto;
      }

      .button {
        font-size: 0.9rem;
        padding: 0.25rem 0.75rem;
      }

      .chat-navigate-back{
        transform: rotate(0.25turn);
        cursor: pointer;
      }
    }

    &__body {
      background-color: white;
      flex-grow: 1;
      border: 2px solid $gray-chat;
      overflow-y: auto;
      overscroll-behavior: contain;

      &.loading {
        display: grid;
        place-content: center;
      }

      .chat-messages {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        padding: 1rem;

        .message {
          display: flex;
          flex-direction: column;
          padding: 0.35rem 1rem;
          border-radius: 1.2rem;
          background-color: hsl(0, 0%, 60%);
          color: white;
          font-weight: bold;
          width: fit-content;
          max-width: 75%;
          transition: background-color 200ms ease;
          font-size: 0.9rem;

          .time {
            margin-left: auto;
            font-size: 0.7rem;
            opacity: 0.75;
          }

          &.loading {
            animation: message-skeleton 500ms ease infinite alternate;
          }

          @keyframes message-skeleton {
            from {
              background-color: hsl(0, 0%, 60%);
            }

            to {
              background-color: hsl(0, 0%, 75%);
            }
          }


          &--left {
            background-color: hsl(120, 75%, 45%);
            margin-right: auto;
          }

          &--right {
            background-color: hsl(240, 70%, 55%);
            margin-left: auto;
            justify-content: end;
          }

          details {
            summary {
              margin-top: 0.4rem;
              cursor: pointer;
              opacity: 0.8;
  
              &:hover{
                text-decoration: underline;
              }
            }

            // &[open] summary {
            //   opacity: 1;
            // }
          }


          &-filelist {
            border-radius: 4px;
            background-color: $gray-chat;
            padding: 0.6rem;
            margin-block: 0.4rem;
            list-style: none;
            display: flex;
            flex-direction: column;
            row-gap: 0.2rem;
            
            li {
              &:hover {
                a {
                  text-decoration: underline;
                }
              }
            }


            a {
              color: black;
            }
          }
        }
      }
    }

    &__footer {
      position: relative;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      padding: 1rem;
      background-color: $gray-chat;

      .chat__input {
        flex-grow: 1;
        border: 1px solid $blue;
        border-radius: 0.3rem;
        padding: 0.5rem;
      }

      .send-icon {
        flex-basis: 35px;
        height: 100%;
        cursor: pointer;
        color: $blue;
      }

      .attachment-icon {
        transform: rotate(270deg);
        color: $blue;
        cursor: pointer;
      }

      .message-attachments {
        position: absolute;
        inset: auto 0 100% 0;
        padding: 0.7rem 1.4rem;
        background-color: whitesmoke;
        border-top: 1px solid $blue;
        display: flex;
        align-items: center;
        justify-content: space-between;

        small {
          flex-shrink: 0;
        }
      }
    }
  }
}