@function rem($px) {
  @return $px / $font-size + rem;
}

// Colors
$black: #000;
$white: #FFF;
$gray: #979797;
$blue: #0080C3;
$yellow: #FFC400;
$urgent: #FFC400;
$green-ok: #16BD00;

// Font
$font-size: 16;
$font-montserrat: 'Montserrat', sans-serif;
$font-attila: 'Attila_Sans', sans-serif;

//
$header: 82px;
$header-mobile: 100px;
$header-tablet: 120px;

//
$gap: 32px;
$mobile: 600px + (2 * $gap);
$desktop: 980px + (2 * $gap);
$tablet: 768px;
$button-radius: 9999px;

//icons width
$iconw: 52px;

//text basic
$txtb: rem(18);
