.billing {
  margin: 0 auto;
  & th {
    padding-bottom: 15px;
  }
  &  td{
    
  }
  &__link {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    text-transform: uppercase;
    background-image: url(../images/download-bt.svg);
    background-repeat: no-repeat;
    background-position: center right;
    border: 1px solid $blue;
    border-radius: 999px;
    transition: transform .16s ease-in-out;
  }
  &__link:hover {
    background-image: url(../images/download-bt-on.svg);
    border-color: $yellow;
    transform: scale(1.10);
  }
  &__col8 {
    width: 66.6666%;
  }
  &__col4 {
    width: 33.3333%;
  }
}
