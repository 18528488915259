.tooltip_templates {
  display: none;
}

.tooltipster-base {
  z-index: 99999 !important;
  // min-width: 280px;
}

.tooltipster-content {
  padding: 0 !important;
}

.tooltipster-arrow-background {
  top: 0 !important;
}

.tooltipster-black {
  .tooltipster-arrow-border,
  .tooltipster-arrow-background {
    border-top-color: $black !important;
    border-bottom-color: $black !important;
  }

  .tooltipster-box {
    border: 0;
    border-radius: 8px;
    background-color: $black;
  }
}

.tooltipster-white {
  .tooltipster-arrow-border {
    border-top-color: rgba(0, 0, 0, .5) !important;
    border-bottom-color: rgba(0, 0, 0, .5) !important;
  }
  .tooltipster-arrow-background {
    border-top-color: $white !important;
    border-bottom-color: $white !important;
    top: 0 !important;
  }

  .tooltipster-box {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .5);
    border: 0;
    border-radius: 8px;
    background-color: $white;
  }

  .tooltipster-content {
    color: $black;
  }
}

.tooltip {
  padding: rem(18) rem(20) rem(16);

  &__profile-nav {
    padding-top: rem(10);
    padding-bottom: rem(10);
    font-size: rem(14);
    font-weight: 700;
    text-align: center;
  }
  &__profile-nav-link {
    display: block;
    text-decoration: none;
    color: $white;
    margin-bottom: rem(4);
    &:last-child {
      margin-bottom: rem(0);
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.tooltip-center {
  padding: 0 !important;
}

.tooltip-alert {
  display: flex;
  align-items: center;
  gap: rem(10);
  padding-top: rem(12);
  padding-bottom: rem(12);
  padding-right: rem(10);
  padding-left: rem(10);
  font-size: rem(14);
  color: $black;
  border-bottom: 1px solid #f7f7f7;
  transition: background-color 160ms ease-in-out;

  &:hover {
    background-color: #f7f7f7;
  }

  &:active, &:focus-within{
    background-color: #dbdbdb;
  }

  &__content{
    display: flex;
    align-items: center;
    gap: rem(10);
    flex-grow: 1;
    width: 100%;
    cursor: pointer;
    
    button[type="submit"]{
      background: transparent;
      border: none;
      text-align: left;
      display: flex;
      align-items: center;
      gap: rem(10);
      padding: 0;
    }

    .tooltip-alert__logo {
      max-width: 38px;
      flex-shrink: 0;

      img {
        width: 38px;
        height: 38px;
        border-radius: 999px;
        border: 1px solid $blue;
      }
    }
  }

  &__dismiss{
    margin-left: auto;
    flex-shrink: 0;
    cursor: pointer;
  }

  a {
    color: inherit;
  }
}

.tooltip-cta {
  display: block;
  padding: rem(16) rem(12);
  text-align: center;
  font-size: rem(14);
  font-weight: 700;
  color: $white;
  background-color: $blue;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: background-color 160ms ease-in-out;

  &:hover {
    background-color: transparentize($blue, .1)
  }
}

.tooltip-notifications {
  max-height: 300px;
  overflow: auto;
}

.tooltip-filters {
  margin: 0 !important;
  padding: rem(6) rem(10);
  background-color: #efefef;

  .is-12 {
    padding-bottom: 0 !important;
  }

  &__img {
    display: block;
    margin: 0 auto;
    width: 55px;
  }

  .column {
    padding: rem(4);
  }

}

