.product {
  & .container {
    max-width: 1800px;
  }
  & .container--box {
    margin: 0 auto;
  }
  &-box {
    background-color: #f6f6f6;
    border-radius: rem(7);
    padding: rem(20) rem(20) rem(20) rem(20);
    @media (min-width: $tablet) {
      margin: 0 auto;
      padding: rem(30) rem(50) rem(50) rem(50);
    }
    @media (max-width: $tablet) {
      margin-bottom: 75px;
    }
    & .swiper {
      border-radius: rem(7);
    }
  }
  & .is-12-mobile .ad__info-item img {
    width: rem(32);
    height: auto;
  }

  &__name {
    font-size: rem(36);
    margin-bottom: 0;
    overflow-wrap: anywhere;
    color: $blue;
    
    @media (min-width: $tablet) {
      font-size: rem(46);
      line-height: rem(46);
      margin-bottom: rem(15);
    }
  }
  
  &__description {
    font-size: $txtb;
    margin-bottom: rem(20);
  }

  &__meta {
    padding: rem(20);
    background-color: #f7f7f7;
  }

  &__top {
    padding-top: rem(20);
  }

  &__img-wrapper {
    margin-bottom: rem(20);
  }

  &__img {
    width: 100%;
    display: block;
  }

  &__business {
    font-size: rem(14);

    .business__name {
      font-size: rem(16);
      margin-bottom: 0;
    }
    .business__location {
      margin-bottom: rem(6);
    }
  }

}

.product-header {
  margin-bottom: rem(20);

  &__right {
    @media (min-width: $tablet) {
      text-align: right;
    }
  }
}

.product-btns {
  margin-bottom: rem(20);
  
  @media (min-width: $tablet) {
    text-align: right;
    //margin-top: rem(-20);
    margin-bottom: rem(-20);
    min-height: 58px;

    &.no-docs{
      margin-top: 0;
      margin-bottom: rem(20);
    }
  }
}

.product-actions {
  img {
    opacity: .3;
    &:hover {
      opacity: 1;
    }
  }
}

.product-docs {
  margin-bottom: rem(36);

  &__title {
    // padding-bottom: rem(20);
    // border-bottom: 1px solid #979797;

    h2 {
      display: inline-block;
      margin-right: rem(12);
      margin-bottom: 0;
      font-size: rem(18);
    }
    img {
      position: relative;
      top: 8px;
      vertical-align: super;
    }
  }
  &__link {
    color: $blue;
    text-transform: uppercase;
    background-image: url(../images/download-bt.svg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: $iconw auto;
    padding-top: 20px;
    padding-right: 70px;
    padding-bottom: 20px;
    font-size: rem(22);
  }
  &__link:hover {
    background-image: url(../images/download-bt-on.svg);
  }

  &__doc {
    display: flex;
    align-items: center;
    padding-top: rem(20);
    padding-bottom: rem(20);
    border-bottom: 1px solid #E0E0E0;

    &:hover {
      background-color: #f7f7f7;
    }
  }

  &__type {
    margin-left: rem(10);
    margin-right: rem(10);
    img {
      display: block;
    }
  }

  &__name {
    color: $black;
  }

}

.product-offers {
  font-size: rem(14);

  .table-wrapper {
    overflow-x: auto;
  }

  table {
    table-layout: fixed;
    border-collapse: collapse;
    @media (min-width: $tablet) {
      width: 100%;
    }

    thead {
      background-color: rgba(149, 149, 149, 0.2);
      text-align: left;
    }

    tr:nth-child(2n) {
      background-color: rgba(149, 149, 149, 0.1);
    }

    th, td {
      padding: rem(6) rem(16);
    }

    .rating {
      font-style: italic;
      font-size: rem(12);
    }

    .rating__star {
      width: 13px;
      height: 13px;
    }
  }
}

.round {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  display: inline-block;
  background-color: red;
  
  &.green {
    background-color: $green-ok;
  }
}