.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  // height: $header;
  padding-top: rem(22);
  padding-bottom: rem(22);
  /*box-shadow: 0 0 10px #ccc;*/
  border-bottom: 3px solid #e6e6e6;
  background-color: $white;

  &__container {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    display: flex;
    align-items: center;
    & .menu-burguer {
      margin-left: rem(20);
    }
  }

  &__beta {
    position: absolute;
    color: white;
    background-color: #0080C3;
    font-size: 12px;
    top: 10px;
    left: 10px;
    z-index: 2000;
    padding-inline: 10px;
    border-radius: 10px;
  }

  @media (max-width: $tablet) {
    height: $header-tablet;
    padding-top: rem(12);
    padding-bottom: rem(12);

    &__container {
      max-width: 100%;
      display: block;
      text-align: center;
    }

    &__left {
      display: block;
      margin-bottom: 10px;
    }

    &__right {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding-top: rem(12);
      padding-bottom: rem(12);
      background-color: $white;
      box-shadow: 0 -3px 3px #ccc;
    }
  }
  @media (max-width: $mobile) {
    height: $header-mobile;

    &__beta {
        position: absolute;
        color: white;
        background-color: #0080C3;
        font-size: 9px;
        top: 6px;
        left: 6px;
        z-index: 2000;
        padding-inline: 5px;
        border-radius: 10px;
      }
  }

  &__logo {
    @media (max-width: $mobile) {
      width: 140px;
    }
  }
}

.nav {
  display: flex;
  align-items: center;

  @media (max-width: $tablet) {
    justify-content: space-evenly;
    max-width: 320px;
    margin: 0 auto;
  }

  .ic {
    width: $iconw;
    height: $iconw;
    position: relative;

    &.show-count{
      &::after{
        content: attr(data-notifications);
        position: absolute;
        top: 0;
        left: 100%;
        color: white;
        background-color: #ff4040;
        transform: translate(-60%, -30%);
        padding: 4px;
        width: 10px;
        height: 10px;
        font-size: 12px;
        border-radius: 100%;
        display: grid;
        text-align: center;
        place-content: center;
        font-style: normal;
        border: 2px solid white;
      }
    }

    &.show-warning{
      border: 1px solid #ff4040;
      &::after{
        content: attr(data-notifications);
        position: absolute;
        top: 0;
        left: 100%;
        color: white;
        background-color: #ff4040;
        transform: translate(-60%, -30%);
        padding: 4px;
        width: 10px;
        height: 10px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 100%;
        display: grid;
        text-align: center;
        place-content: center;
        font-style: normal;
        border: 2px solid white;
      }
    }
  }

  &__add {
    display: flex;
    width: $iconw;
    height: $iconw;
    justify-content: center;
    align-items: center;
    background-image: url(../images/icon-add.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 16px auto;

    @media (min-width: $widescreen) {
      padding: rem(2) rem(42) rem(2) rem(18);
      background-position: right 10px top 50%;
      width: initial;
      background-size: 16px auto;
    }

    @media (max-width: 1215px) {
      background-image: url(../images/btns/icon-add-01.svg);
      background-size: $iconw auto;
      &:hover {
        background-image: url(../images/btns/icon-add-01-on.svg);
      }
    }

  }

  &__add-text {
    display: none;
    @media (min-width: $widescreen) {
      display: inline;
    }
  }
}

.search-bar {
  overflow: hidden;
  display: flex;
  height: $iconw;
  flex-flow: row nowrap;
  align-items: stretch;
  border: 0;

  @media (max-width: $mobile) {
    height: 32px;
  }

  @media (max-width: $tablet) {
    &.active {
      .search-bar__checks {
        padding: 0;
        max-width: 0;
        overflow: hidden;
      }
      .search-bar__input {
        margin-left: 16px;
      }
    }
  }

  &__checks {
    padding: 2px rem(18);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    transition: all .3s ease;

    label {
      cursor: pointer;
      margin-right: rem(12);
      font-size: $txtb;
      font-weight: 400;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $desktop) {
        margin-right: rem(8);
        font-size: rem(12);
      }

      &:last-child {
        margin-right: 0;
      }

      input {
        margin: 0 6px 0 0;
      }
    }
  }

  &__input {
    display: block;
    border-top: 1px solid $blue;
    border-bottom: 1px solid $blue;
    border-right: none;
    border-left: 1px solid $blue;
    border-top-left-radius:  $button-radius !important;
    border-bottom-left-radius: $button-radius !important;
    padding: 2px rem(12);
    font-size: rem(16);


    @media only screen and (min-width: 1517px) {
      min-width: 400px;
    }


    @media only screen and (min-width: 768px) and (max-width: 1450px) {
      max-width: 120px;
    }



    @media (max-width: $desktop) {
      max-width: 100px;
    }

    @media (max-width: $tablet) {
      width: 100%;
      max-width: 100%;
    }
    &:focus-visible {
      outline: none !important;
    }
  }
  &__btnbox {
    border-top: 1px solid $blue;
    border-bottom: 1px solid $blue;
    border-right: 1px solid $blue;
    border-left: none;
    border-top-right-radius:  $button-radius;
    border-bottom-right-radius: $button-radius;
  }
  &__btn {
    cursor: pointer;
    background-image: url(../images/icon-search.svg);
    background-repeat: no-repeat;
    background-position: right 50% top 50%;
    padding: 3px 22px;
    margin: 0;
      background-size: 16px auto;
      height: 50px;

    @media (max-width: $mobile) {
      height: 30px;
    }

    @media (min-width: $widescreen) {
      padding: 5px rem(46) 5px rem(28);
      background-position: right 10px top 50%;
    }
  }

  &__btn-text {
    display: none;
    @media (min-width: $widescreen) {
      display: inline;
    }
  }
}
.menu-burguer {
  display: inline-block;
  width: rem(28);
  height: rem(20);
  background-image: url(../images/icon-menu.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.burguermenu {
  list-style: none;
  padding-left: rem(15);
  padding-right: rem(15);
  & li {
    padding-bottom: rem(15);
    margin-bottom: rem(15);
    border-bottom: 1px solid $blue;
    & a:link, & a:visited, & a:active, & a:focus {
      font-size: rem(22);
      color: #1d1d1b;
    }
  }
  &__lilast {
    border-bottom: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}
