.collab-list {
    margin-top: 2rem;
    &.active {
      .collab-list__arrow {
        transform: rotate(180deg);
      }
      .collab-list__header .collab-list__action {
        opacity: 1;
      }
      .collab-list__content {
        display: block;
      }
    }
  
    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      border-bottom: 1px solid $black;
  
      &:hover {
        .collab-list__action {
          opacity: 1;
        }
      }
    }
    
    &__name {
      margin-bottom: 0;
      padding: rem(14) rem(10);
      cursor: pointer;
      
      @media (max-width: $mobile) {
        padding: rem(14) rem(0);
        font-size: 16px;
      }
    }
    
    &__item {
      width: 100%;
      border-collapse: collapse;
  
      tr:hover {
        .collab-list__action {
          opacity: 1;
        }
      }
  
      tr td {
        padding-top: rem(6);
        padding-bottom: rem(6);
        border-bottom: 1px solid $gray;
        background-color: $white;
        border-right: 0;
        border-left: 0;
      }
  
      label {
        display: block;
        height: 100%;
      }
    }
  
    &__actions {
      flex: 1 0 130px;
      margin-left: auto;
      padding-right: rem(10);
      padding-left: rem(10);
      text-align: right;
    }
  
    &__action {
      display: inline-block;
      padding: rem(4);
      cursor: pointer;
      opacity: 0;
      transition: opacity .16s ease-in-out;
      &.on {
        opacity: 1;
      }
    }
    
    &__arrow {
      transform: rotate(0);
      opacity: 1;
    }
    
    &__content {
      display: none;
      margin-bottom: rem(40);
    }
  
    &__item-logo {
      padding-right: rem(10);
      padding-left: rem(10);
  
      img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        max-width: inherit;
      }
    }
  
    &__item-name {
      flex: 0 1 100%;
      padding-right: rem(10);
      padding-left: rem(10);
    }
  
    &__item-actions {
      flex: 1 0 160px;
      margin-left: auto;
      padding-right: rem(10);
      padding-left: rem(10);
      text-align: right;
    }
  }

  .search-collaborators{
    min-height: 500px;
    &__input{
      max-width: 350px;
    }
    &__results{
      max-width: 450px;

      @media (max-width: $mobile) {
        max-height: 300px;
        overflow-y: auto;
        padding-left: 1px;
        padding-right: 1px;
      }

      .search-item{
        padding: 1rem;
        cursor: pointer;
        transition: background-color 100ms ease;

        &:nth-child(even){
          background-color: white;
        } 
        &:nth-child(odd){
          background-color: whitesmoke;
        }
        &:hover{
          background-color: #c5c5c5;
        }

        &:focus-visible, &:active, &.active{
          outline: 1px solid $blue;
          border-radius: 0.2rem;
          background-color: #c5c5c5;
        }
      }
    }
  }