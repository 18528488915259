.business {
  &__name {
    color: $blue;
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__logo {
    width: 100%;
  }
  &__location {
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }
  &__gallery {
    cursor: pointer;
    margin-top: rem(20);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  
  &__badge {
    display: inline-block;
    position: relative;
    top: rem(4);
    width: rem(20);
    height: rem(20);
    img {
      width: rem(20);
      margin-left: 5px;
    }
  }
}