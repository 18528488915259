.location {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  &__icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
    //top: 4px;
    margin-right: 5px;
  }
}