.ad-list {
  overflow: hidden;
  padding-top: rem(20);
  padding-bottom: rem(80);
  &.container {
    max-width: 1800px;
  }
}

.ad {
  height: 100%;
  position: relative;
  padding-bottom: rem(10);
  background-color: #f6f6f6;
  border-radius: rem(7);
  overflow: hidden;

  &.business-card {

    position: relative;
    padding-bottom: 155px;
  }

  @media (max-width: $mobile) {
    padding-bottom: 0;
  }
  & .rating__stars {
    display: flex;
    padding-top: rem(5);
    padding-bottom: rem(5);
  }

  &--urgent {
    border: 5px solid $urgent;
  }

  > .columns {
    height: 100%;
    margin: 0;
    flex-flow: column nowrap;

    @media (max-width: $tablet) {
      flex-flow: row wrap;
    }

    .column {
      margin-bottom: rem(10);
      padding: 0 rem(20);

      @media (max-width: $tablet) {
        padding: 0 rem(10);
      }

      &.blk4 {
        margin-top: auto;
      }

      &--wide {
        padding: 0 rem(10);
      }
      &--nowide {
        padding: 0;
      }
    }
  }

  &__title {
    margin: 0;
    font-size: rem(30);
    line-height: rem(33);
    font-weight: 700;
    color: $blue;
    overflow-wrap: anywhere;
    text-transform: uppercase;

    @media (max-width: $mobile) {
      font-size: $txtb;
    }

    > a {
      transition: color .16s ease-in-out;
      &:hover {
        color: $black;
      }
    }
  }

  &__img-bg {
    height: 160px;
    background-size: cover;
    background-position: 50%;

    // @media (max-width: $tablet) {
    //   height: auto;
    //   padding-bottom: 100%;
    // }
  }
  &__needs {
    font-size: rem(12);
    color: $blue;
  }

  &__description {
    font-size: $txtb;
    line-height: rem(23);
    margin-bottom: rem(10);
    /* word-break: break-all; */
  }

  &__end {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    align-items: end;
    padding: rem(15) rem(20) rem(20);
    flex-flow: row wrap;
    margin-left: 0;
    margin-right:0;
    .column {
      margin-bottom: 0 !important;
      &--left {
        padding: rem(0) rem(0) rem(0) rem(0) !important;
      }
      &--rigth {
        padding: rem(0) rem(0) rem(0) rem(0) !important;
      }
    }
    & .business__name {
      &--box {
        border-top: 1px solid $blue;
        padding: 0 !important;
        width: 100%;
        flex: none;
        min-height: 1px;
        display: flex;
        align-items: center;
        &.offer-status {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
        & span {
          font-weight: 400;
          text-transform: uppercase;
          color: $blue;
          font-size: rem(13);
          display: inline-block;

        }
      }
    }

    & h3 {
      margin-bottom: 0;
      padding-top: 10px;
    }
  }
  &__business {
    margin-top: rem(10);
    font-size: rem(14);
    line-height: 1;

    @media (max-width: $tablet) {
      margin-top: rem(6);
    }

    @media (max-width: $mobile) {
      font-size: rem(12);
      line-height: .8;
    }

    .business__name {
      margin-bottom: rem(5);
      font-size: rem(16);
      font-weight: 700;

      > a {
        transition: color .16s ease-in-out;
        &:hover {
          color: $black;
        }
      }
    }

    

    @media (max-width: $mobile) {
      .business__rating {
        display: inline;
        margin-bottom: 20px;
      }
      .business__location {
        display: inline-block;
      }
    }
  }

  &__info-item {
    position: relative;
    padding-top: rem(3);
    margin-bottom: rem(4);
    font-size: $txtb;
    min-height: 24px;

    display: flex;
    align-items: center;

    & strong {
      font-weight: 400;
      text-transform: uppercase;
      color: $blue;
      font-size: rem(13);
      display: inline-block;
      margin-right: rem(7);
    }
    &--product {
      padding-top: rem(0);
      padding-left: rem(0);
      margin-bottom: rem(0);
    }
    &--requirements {
      margin-top: 1.4rem;
      margin-bottom: 1rem;
      align-items: flex-start;
      flex-flow: column;
      & .ad__info-item-img {
        margin-top: 2px;
      }
      & ul {
        margin: 10px 0;
        width: 100%;
      }
      & .requirements-tit {
        display: flex;
        align-items: center;
      }
    }

  }

  &__info-item-img {
    margin-right: rem(7);
    width: rem(15);

    @media (max-width: $mobile) {
      width: 18px;
      height: 18px;
    }
  }

  &__actions {
    padding-top: rem(5);
    padding-bottom: rem(15);
    border-bottom: 1px solid $blue;
    min-height: rem(69);
    @media (max-width: $tablet) {
     text-align: left;
     display: flex;
     align-items: center;
     justify-content: flex-start;
    }
    &--noborder {
      border: none;
    }

  }

  &__ref {
    position: absolute;
    top: 60px;
    right: 10px;
    padding: 6px 10px;
    background-color: $black;
    color: $white;

    @media (max-width: $mobile) {
      top: initial;
      bottom: 70px;
      right: 0;
    }
  }

  &__badge {
    position: absolute;
    top: -6px;
    right: 10px;
  }

  &__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(10);

    @media (max-width: $tablet) {
      //display: inline-block;
      //margin-top: rem(0);
      padding: rem(6) rem(10);
      margin-left: auto;
    }

    @media (max-width: $mobile) {
      font-size: rem(16);
      margin-top: 20px;
      margin-bottom: 10px;
    }

  }

  .location__icon {
    width: rem(17);
    height: auto;
  }
}


.ad--noimg {
  padding-bottom: 2rem;
  .blk5 {
    flex-grow: 0;
    margin-top: auto;
  }
  .ad__actions {
    margin-top: rem(10);
  }
}


@media (max-width: $tablet) {
  .ad {
    .blk1 {
      order: 1;
    }
    .blk2 {
      order: 4;
    }
    .blk3 {
      order: 2;
    }
    .blk4 {
      order: 3;
    }
    .blk5 {
      order: 5;
    }
  }
}

.ad-tooltip {
  &__link {
    display: block;
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
  &__icon {
    position: relative;
    vertical-align: middle;
    top: -3px;
    margin-right: 3px;
  }
}


.business__name {
  &--box {
    border-top: 1px solid $blue;
    padding: 0 !important;
    width: 100%;
    flex: none;
    min-height: 1px;
    display: flex;
    align-items: center;
    &.offer-status {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    & span {
      font-weight: 400;
      text-transform: uppercase;
      color: $blue;
      font-size: rem(13);
      display: inline-block;

    }
  }
}


.business__logo-wrapper {
  width: 50px !important;
  margin-top: rem(8) !important;
  margin-right: rem(8) !important;
  margin-bottom: rem(8) !important;
  padding: 0 !important;

  @media (max-width: $mobile) {
    display: none;
  }

  img {
    display: block;
    height: 50px;
    border-radius: 100%;
  }

  @media (max-width: $tablet) {
    width: 30px;
  }
}
