/* Bulma Utilities */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}
.columns.is-mobile > .column.is-narrow {
  flex: none;
  width: unset;
}
.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}
.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}
.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}
.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}
.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}
.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}
.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}
.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}
.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}
.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}
.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}
.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}
.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}
.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}
.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}
.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}
.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}
.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}
.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}
.columns.is-mobile > .column.is-0 {
  flex: none;
  width: 0%;
}
.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}
.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.33333337%;
}
.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333337%;
}
.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.66666674%;
}
.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.66666674%;
}
.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}
.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}
.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.33333337%;
}
.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.33333337%;
}
.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.66666674%;
}
.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.66666674%;
}
.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}
.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}
.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.33333337%;
}
.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.33333337%;
}
.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.66666674%;
}
.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.66666674%;
}
.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}
.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}
.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.33333337%;
}
.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.33333337%;
}
.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.66666674%;
}
.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.66666674%;
}
.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}
.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}
@media screen and (max-width: 767px) {
  .column.is-narrow-mobile {
    flex: none;
    width: unset;
  }
  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-mobile {
    margin-left: 50%;
  }
  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }
  .column.is-0-mobile {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-mobile {
    margin-left: 0%;
  }
  .column.is-1-mobile {
    flex: none;
    width: 8.33333337%;
  }
  .column.is-offset-1-mobile {
    margin-left: 8.33333337%;
  }
  .column.is-2-mobile {
    flex: none;
    width: 16.66666674%;
  }
  .column.is-offset-2-mobile {
    margin-left: 16.66666674%;
  }
  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-mobile {
    margin-left: 25%;
  }
  .column.is-4-mobile {
    flex: none;
    width: 33.33333337%;
  }
  .column.is-offset-4-mobile {
    margin-left: 33.33333337%;
  }
  .column.is-5-mobile {
    flex: none;
    width: 41.66666674%;
  }
  .column.is-offset-5-mobile {
    margin-left: 41.66666674%;
  }
  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-mobile {
    margin-left: 50%;
  }
  .column.is-7-mobile {
    flex: none;
    width: 58.33333337%;
  }
  .column.is-offset-7-mobile {
    margin-left: 58.33333337%;
  }
  .column.is-8-mobile {
    flex: none;
    width: 66.66666674%;
  }
  .column.is-offset-8-mobile {
    margin-left: 66.66666674%;
  }
  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-mobile {
    margin-left: 75%;
  }
  .column.is-10-mobile {
    flex: none;
    width: 83.33333337%;
  }
  .column.is-offset-10-mobile {
    margin-left: 83.33333337%;
  }
  .column.is-11-mobile {
    flex: none;
    width: 91.66666674%;
  }
  .column.is-offset-11-mobile {
    margin-left: 91.66666674%;
  }
  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}
@media screen and (min-width: 768px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    flex: none;
    width: unset;
  }
  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }
  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }
  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }
  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }
  .column.is-0, .column.is-0-tablet {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }
  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333337%;
  }
  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333337%;
  }
  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.66666674%;
  }
  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.66666674%;
  }
  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }
  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.33333337%;
  }
  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.33333337%;
  }
  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.66666674%;
  }
  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.66666674%;
  }
  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }
  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.33333337%;
  }
  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.33333337%;
  }
  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.66666674%;
  }
  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.66666674%;
  }
  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }
  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.33333337%;
  }
  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.33333337%;
  }
  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.66666674%;
  }
  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.66666674%;
  }
  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}
@media screen and (max-width: 1043px) {
  .column.is-narrow-touch {
    flex: none;
    width: unset;
  }
  .column.is-full-touch {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-touch {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-touch {
    margin-left: 50%;
  }
  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }
  .column.is-0-touch {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-touch {
    margin-left: 0%;
  }
  .column.is-1-touch {
    flex: none;
    width: 8.33333337%;
  }
  .column.is-offset-1-touch {
    margin-left: 8.33333337%;
  }
  .column.is-2-touch {
    flex: none;
    width: 16.66666674%;
  }
  .column.is-offset-2-touch {
    margin-left: 16.66666674%;
  }
  .column.is-3-touch {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-touch {
    margin-left: 25%;
  }
  .column.is-4-touch {
    flex: none;
    width: 33.33333337%;
  }
  .column.is-offset-4-touch {
    margin-left: 33.33333337%;
  }
  .column.is-5-touch {
    flex: none;
    width: 41.66666674%;
  }
  .column.is-offset-5-touch {
    margin-left: 41.66666674%;
  }
  .column.is-6-touch {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-touch {
    margin-left: 50%;
  }
  .column.is-7-touch {
    flex: none;
    width: 58.33333337%;
  }
  .column.is-offset-7-touch {
    margin-left: 58.33333337%;
  }
  .column.is-8-touch {
    flex: none;
    width: 66.66666674%;
  }
  .column.is-offset-8-touch {
    margin-left: 66.66666674%;
  }
  .column.is-9-touch {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-touch {
    margin-left: 75%;
  }
  .column.is-10-touch {
    flex: none;
    width: 83.33333337%;
  }
  .column.is-offset-10-touch {
    margin-left: 83.33333337%;
  }
  .column.is-11-touch {
    flex: none;
    width: 91.66666674%;
  }
  .column.is-offset-11-touch {
    margin-left: 91.66666674%;
  }
  .column.is-12-touch {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1044px) {
  .column.is-narrow-desktop {
    flex: none;
    width: unset;
  }
  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-desktop {
    margin-left: 50%;
  }
  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }
  .column.is-0-desktop {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-desktop {
    margin-left: 0%;
  }
  .column.is-1-desktop {
    flex: none;
    width: 8.33333337%;
  }
  .column.is-offset-1-desktop {
    margin-left: 8.33333337%;
  }
  .column.is-2-desktop {
    flex: none;
    width: 16.66666674%;
  }
  .column.is-offset-2-desktop {
    margin-left: 16.66666674%;
  }
  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-desktop {
    margin-left: 25%;
  }
  .column.is-4-desktop {
    flex: none;
    width: 33.33333337%;
  }
  .column.is-offset-4-desktop {
    margin-left: 33.33333337%;
  }
  .column.is-5-desktop {
    flex: none;
    width: 41.66666674%;
  }
  .column.is-offset-5-desktop {
    margin-left: 41.66666674%;
  }
  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-desktop {
    margin-left: 50%;
  }
  .column.is-7-desktop {
    flex: none;
    width: 58.33333337%;
  }
  .column.is-offset-7-desktop {
    margin-left: 58.33333337%;
  }
  .column.is-8-desktop {
    flex: none;
    width: 66.66666674%;
  }
  .column.is-offset-8-desktop {
    margin-left: 66.66666674%;
  }
  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-desktop {
    margin-left: 75%;
  }
  .column.is-10-desktop {
    flex: none;
    width: 83.33333337%;
  }
  .column.is-offset-10-desktop {
    margin-left: 83.33333337%;
  }
  .column.is-11-desktop {
    flex: none;
    width: 91.66666674%;
  }
  .column.is-offset-11-desktop {
    margin-left: 91.66666674%;
  }
  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1216px) {
  .column.is-narrow-widescreen {
    flex: none;
    width: unset;
  }
  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }
  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }
  .column.is-0-widescreen {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }
  .column.is-1-widescreen {
    flex: none;
    width: 8.33333337%;
  }
  .column.is-offset-1-widescreen {
    margin-left: 8.33333337%;
  }
  .column.is-2-widescreen {
    flex: none;
    width: 16.66666674%;
  }
  .column.is-offset-2-widescreen {
    margin-left: 16.66666674%;
  }
  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }
  .column.is-4-widescreen {
    flex: none;
    width: 33.33333337%;
  }
  .column.is-offset-4-widescreen {
    margin-left: 33.33333337%;
  }
  .column.is-5-widescreen {
    flex: none;
    width: 41.66666674%;
  }
  .column.is-offset-5-widescreen {
    margin-left: 41.66666674%;
  }
  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }
  .column.is-7-widescreen {
    flex: none;
    width: 58.33333337%;
  }
  .column.is-offset-7-widescreen {
    margin-left: 58.33333337%;
  }
  .column.is-8-widescreen {
    flex: none;
    width: 66.66666674%;
  }
  .column.is-offset-8-widescreen {
    margin-left: 66.66666674%;
  }
  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }
  .column.is-10-widescreen {
    flex: none;
    width: 83.33333337%;
  }
  .column.is-offset-10-widescreen {
    margin-left: 83.33333337%;
  }
  .column.is-11-widescreen {
    flex: none;
    width: 91.66666674%;
  }
  .column.is-offset-11-widescreen {
    margin-left: 91.66666674%;
  }
  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1408px) {
  .column.is-narrow-fullhd {
    flex: none;
    width: unset;
  }
  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }
  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }
  .column.is-0-fullhd {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }
  .column.is-1-fullhd {
    flex: none;
    width: 8.33333337%;
  }
  .column.is-offset-1-fullhd {
    margin-left: 8.33333337%;
  }
  .column.is-2-fullhd {
    flex: none;
    width: 16.66666674%;
  }
  .column.is-offset-2-fullhd {
    margin-left: 16.66666674%;
  }
  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }
  .column.is-4-fullhd {
    flex: none;
    width: 33.33333337%;
  }
  .column.is-offset-4-fullhd {
    margin-left: 33.33333337%;
  }
  .column.is-5-fullhd {
    flex: none;
    width: 41.66666674%;
  }
  .column.is-offset-5-fullhd {
    margin-left: 41.66666674%;
  }
  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }
  .column.is-7-fullhd {
    flex: none;
    width: 58.33333337%;
  }
  .column.is-offset-7-fullhd {
    margin-left: 58.33333337%;
  }
  .column.is-8-fullhd {
    flex: none;
    width: 66.66666674%;
  }
  .column.is-offset-8-fullhd {
    margin-left: 66.66666674%;
  }
  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }
  .column.is-10-fullhd {
    flex: none;
    width: 83.33333337%;
  }
  .column.is-offset-10-fullhd {
    margin-left: 83.33333337%;
  }
  .column.is-11-fullhd {
    flex: none;
    width: 91.66666674%;
  }
  .column.is-offset-11-fullhd {
    margin-left: 91.66666674%;
  }
  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
}
.columns:last-child {
  margin-bottom: -0.75rem;
}
.columns:not(:last-child) {
  margin-bottom: calc(1.5rem - 0.75rem);
}
.columns.is-centered {
  justify-content: center;
}
.columns.is-gapless {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}
.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}
.columns.is-gapless:last-child {
  margin-bottom: 0;
}
.columns.is-mobile {
  display: flex;
}
.columns.is-multiline {
  flex-wrap: wrap;
}
.columns.is-vcentered {
  align-items: center;
}
@media screen and (min-width: 768px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}
@media screen and (min-width: 1044px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: 0.75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}
.columns.is-variable > .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}
.columns.is-variable.is-0 {
  --columnGap: 0rem;
}
@media screen and (max-width: 767px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 768px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1043px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}
@media screen and (max-width: 1043px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1044px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1044px) and (max-width: 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}
.columns.is-variable.is-1 {
  --columnGap: 0.25rem;
}
@media screen and (max-width: 767px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 768px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1043px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: 0.25rem;
  }
}
@media screen and (max-width: 1043px) {
  .columns.is-variable.is-1-touch {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1044px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1044px) and (max-width: 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: 0.25rem;
  }
}
.columns.is-variable.is-2 {
  --columnGap: 0.5rem;
}
@media screen and (max-width: 767px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 768px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1043px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: 0.5rem;
  }
}
@media screen and (max-width: 1043px) {
  .columns.is-variable.is-2-touch {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1044px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1044px) and (max-width: 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: 0.5rem;
  }
}
.columns.is-variable.is-3 {
  --columnGap: 0.75rem;
}
@media screen and (max-width: 767px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 768px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1043px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: 0.75rem;
  }
}
@media screen and (max-width: 1043px) {
  .columns.is-variable.is-3-touch {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1044px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1044px) and (max-width: 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: 0.75rem;
  }
}
.columns.is-variable.is-4 {
  --columnGap: 1rem;
}
@media screen and (max-width: 767px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 768px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1043px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}
@media screen and (max-width: 1043px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1044px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1044px) and (max-width: 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}
.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}
@media screen and (max-width: 767px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 768px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1043px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}
@media screen and (max-width: 1043px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1044px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1044px) and (max-width: 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}
.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}
@media screen and (max-width: 767px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 768px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1043px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}
@media screen and (max-width: 1043px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1044px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1044px) and (max-width: 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}
.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}
@media screen and (max-width: 767px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 768px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1043px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}
@media screen and (max-width: 1043px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1044px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1044px) and (max-width: 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}
.columns.is-variable.is-8 {
  --columnGap: 2rem;
}
@media screen and (max-width: 767px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 768px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1043px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}
@media screen and (max-width: 1043px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1044px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1044px) and (max-width: 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

@font-face {
  font-family: "Attila_Sans";
  src: url("fonts/AttilaSansClassicTrial-Regular.eot");
  src: local(""), url("fonts/AttilaSansClassicTrial-Regular.woff") format("woff"), url("fonts/AttilaSansClassicTrial-Regular.otf") format("truetype"), url("fonts/AttilaSansClassicTrial-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
html,
body {
  box-sizing: border-box;
  line-height: 1.4;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: #000;
}

body {
  margin: 0;
}

* {
  box-sizing: inherit;
  line-height: inherit;
  font-family: inherit;
  font-style: inherit;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.title {
  font-weight: 700;
  font-size: 1.875rem;
  font-family: "Attila_Sans", sans-serif;
  color: #0080C3;
  font-weight: 400;
}

.title-2 {
  font-size: 1.5rem;
  font-family: "Attila_Sans", sans-serif;
  color: #0080C3;
  font-weight: 400;
}

a {
  color: #0080C3;
  text-decoration: none;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
}

hr {
  border: 0;
  border-bottom: 1px solid #E4E4E4;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  border-radius: 100px;
  background-color: #babac0;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  opacity: 0.4;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

input[type=checkbox] {
  vertical-align: middle;
  width: 1em;
  height: 1em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

input[type=checkbox]:checked {
  background-color: #0080C3;
  border-color: #0080C3;
}

.form-check-input, .form-check-input[type=radio] {
  width: 1em;
  height: 1em;
  margin-top: 0em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:checked, .form-check-input[type=radio]:checked {
  background-color: #0080C3;
  border-color: #0080C3;
}

.form-check-input:checked[type=radio] {
  background-image: url("");
}

input, input[type=text], input[type=password], input[type=email], input[type=button], select, textarea, button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.container {
  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
@media (max-width: 768px) {
  .container {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}
.container--box {
  max-width: 900px;
  margin: 1.25rem 1.25rem;
  padding: 1.25rem;
  background-color: #FFF;
}
@media (min-width: 768px) {
  .container--box {
    margin: 3.75rem auto;
    padding: 3.125rem;
  }
}

.content {
  /* @media (max-width: $mobile) {
    padding-top: $header-mobile;
    padding-bottom: 60px;
  } */
}
@media (max-width: 768px) {
  .content {
    padding-top: 120px;
    padding-bottom: 66px;
  }
}
@media (max-width: 664px) {
  .content {
    padding-top: 0px;
    padding-bottom: 60px;
  }
}

.loading-screen {
  z-index: 800;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  display: none;
}

.login-form {
  width: 420px;
  max-width: 95%;
}

.color-green {
  color: #16BD00;
}

.color-grey {
  background-color: #a0a0a0;
  opacity: 0.6;
}

.text-md {
  font-size: 0.875rem;
}

.text-sm {
  font-size: 0.75rem;
}

.read-more {
  cursor: pointer;
  display: inline-block;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.read-more img {
  width: 20px;
  height: 20px;
}

.text-expiration {
  display: inline-block;
  text-align: center;
  color: #0080C3;
  font-size: 0.8125rem;
  text-transform: uppercase;
}

.popup-iframe-close {
  z-index: 1000;
}

.event-notice {
  border-radius: 0.4rem;
  background-color: rgba(144, 238, 144, 0.3);
  color: green;
  border: 1px solid mediumseagreen;
  padding: 1rem;
}
.event-notice--error {
  background-color: rgba(255, 0, 0, 0.25);
  border: 1px solid red;
  color: firebrick;
}
.event-notice--error ul {
  margin: 0;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.material-icons {
  vertical-align: sub;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.rating1 {
  position: relative;
  margin: auto;
  width: 200px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  padding: 5px;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 0 2px #b3acac;
}

.rating__result1 {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-10px) translateX(-5px);
  z-index: -9;
  font: 3em Arial, Helvetica, sans-serif;
  color: #ebebeb8e;
  pointer-events: none;
}

.rating__star1 {
  font-size: 1.3em;
  cursor: pointer;
  color: #dabd18b2;
  transition: filter linear 0.3s;
}

.rating__star:hover {
  filter: drop-shadow(1px 1px 4px gold);
}

@media screen and (min-width: 1044px) and (max-width: 1306px) {
  .column.is-4-middle {
    flex: 0 0 auto;
    width: 33%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1385px) {
  .profile__content .column.is-3-desktop {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media screen and (min-width: 1386px) {
  .profile__content .column.is-3-desktop {
    flex: 0 0 auto;
    width: 33%;
  }
}

.button {
  display: inline-block;
  border: 2px solid #0080C3;
  border-radius: 9999px;
  padding: 0.375rem 1.5rem;
  text-align: center;
  text-decoration: none;
  background: #0080C3;
  color: #FFF;
  font-size: 1.125rem;
  font-weight: 400;
  cursor: pointer;
  -webkit-appearance: none;
  transition: border-color 0.16s ease-in-out, background-color 0.16s ease-in-out;
}
.button:hover {
  background-color: #FFC400;
  border-color: #FFC400;
}
@media (max-width: 768px) {
  .button {
    font-size: 1rem;
  }
}
.button--slim {
  height: 40px;
  padding: 0.375rem 1.25rem;
}
@media (max-width: 768px) {
  .button--slim {
    height: 36px;
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
  }
}
.button:disabled {
  opacity: 0.5;
}

.support {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  display: inline-block;
  /* border: 2px solid $blue; */
  border-radius: 9999px;
  /* padding: rem(6) rem(12); */
  text-align: center;
  text-decoration: none;
  /* background: $blue; */
  color: #FFF;
  font-size: 1.125rem;
  font-weight: 400;
  cursor: pointer;
  -webkit-appearance: none;
  /* transition:
      border-color .16s ease-in-out,
      background-color .16s ease-in-out;

  &:hover {
      background-color: $yellow;
      border-color: $yellow;
  } */
}
.support img {
  width: 70px;
}
@media (max-width: 768px) {
  .support {
    font-size: 1rem;
  }
}
.support--slim {
  height: 40px;
  padding: 0.375rem 1.25rem;
}
@media (max-width: 768px) {
  .support--slim {
    height: 36px;
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
  }
}
.support:disabled {
  opacity: 0.5;
}

.text-btn {
  display: inline-block;
  min-height: 52px;
  margin: 3px 4px;
  border-radius: 30px;
  padding: 9px 20px;
  vertical-align: bottom;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 400;
  background-color: #0080C3;
  color: #FFF;
  cursor: pointer;
  transition: background-color 0.16s ease-in-out;
}
@media (max-width: 1400px) {
  .text-btn {
    padding: 9px 10px;
  }
}
.text-btn.on {
  background-color: #FFC400;
}
.text-btn:hover {
  background-color: #FFC400;
}

.ic--hide-ad-plus {
  height: 53px !important;
  border: none !important;
}

.ic--hide-ad {
  width: 42px;
  height: 48px;
  background-image: url("../images/btns/hide-ad.svg");
}

.button-offer {
  width: 20px;
  margin-right: 5px;
}

.alert-img {
  width: 18px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.alert-text {
  font-size: 18px;
}

.alert-container {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-bottom: 1rem;
}
.alert-container p {
  margin-bottom: 0;
}

.button-docs {
  margin: 10px 0px;
}

.button-docs a {
  color: black;
}

.ic {
  display: inline-block;
  width: 52px;
  height: 52px;
  margin: 3px 4px;
  vertical-align: bottom;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  cursor: pointer;
  border-radius: 999px;
  transition: transform 0.16s ease-in-out;
  border: 1px solid #0080C3;
}
.ic.on, .ic:hover {
  transform: scale(1.05);
  border: 1px solid #FFC400;
}
.ic--destaca {
  background-image: url("../images/btns/destaca.svg");
}
.ic--destaca.on, .ic--destaca:hover {
  background-image: url("../images/btns/destaca-on.svg");
}
.ic--tag {
  background-image: url("../images/notifications/tag.svg");
}
.ic--tag.on, .ic--tag:hover {
  background-image: url("../images/notifications/tag-on.svg");
}
.ic--deal {
  background-image: url("../images/notifications/deal.svg");
}
.ic--deal.on, .ic--deal:hover {
  background-image: url("../images/notifications/deal-on.svg");
}
.ic--chat {
  background-image: url("../images/notifications/chat.svg");
}
.ic--chat.on, .ic--chat:hover {
  background-image: url("../images/notifications/chat-on.svg");
}
.ic--send {
  background-image: url("../images/notifications/send.svg");
}
.ic--send.on, .ic--send:hover {
  background-image: url("../images/notifications/send-on.svg");
}
.ic--alert {
  background-image: url("../images/btns/alert.svg");
}
.ic--alert.on, .ic--alert:hover {
  background-image: url("../images/btns/alert-on.svg");
}
.ic--user {
  background-image: url("../images/btns/user.svg");
  border-radius: 100%;
  background-size: cover;
}
.ic--user.on, .ic--user:hover {
  background-image: url("../images/btns/user-on.svg");
}
.ic--info {
  background-image: url("../images/btns/info.svg");
  /* transform: rotate(180deg); */
  border-radius: 100%;
  background-size: cover;
}
.ic--info.on, .ic--info:hover {
  background-image: url("../images/btns/info-on.svg");
}
.ic--mail {
  background-image: url("../images/btns/mail.svg");
}
.ic--mail.on, .ic--mail:hover {
  background-image: url("../images/btns/mail-on.svg");
}
.ic--share {
  background-image: url("../images/btns/share.svg");
}
.ic--share.on, .ic--share:hover {
  background-image: url("../images/btns/share-on.svg");
}
.ic--more {
  background-image: url("../images/btns/more.svg");
}
.ic--more.on, .ic--more:hover {
  background-image: url("../images/btns/more-on.svg");
}
.ic--arrow-r {
  background-image: url("../images/btns/arrow-r.svg");
}
.ic--arrow-r.on, .ic--arrow-r:hover {
  background-image: url("../images/btns/arrow-r-on.svg");
}
.ic--businesses {
  background-image: url("../images/btns/businesses.svg");
}
.ic--businesses.on, .ic--businesses:hover {
  background-image: url("../images/btns/businesses-on.svg");
}
.ic--fab {
  background-image: url("../images/btns/ico-edit.svg");
}
.ic--fab.on, .ic--fab:hover {
  background-image: url("../images/btns/ico-edit-on.svg");
}
.ic--businesses-send {
  width: 52px;
  height: 52px;
  background-image: url("../images/btns/message.svg");
}
.ic--businesses-send.on, .ic--businesses-send:hover {
  background-image: url("../images/btns/message-on.svg");
}
.ic--businesses-fav {
  width: 52px;
  height: 52px;
  background-image: url("../images/btns/save.svg");
}
.ic--businesses-fav.on, .ic--businesses-fav:hover {
  background-image: url("../images/btns/save-on.svg");
}
.ic--businesses-fav.active {
  background-image: url("../images/btns/save-on.svg");
}
.ic--hide-ad {
  width: 52px;
  height: 52px;
  background-image: url("../images/btns/hide-ad.svg");
  border-radius: 0;
}
.ic--hide-ad.on, .ic--hide-ad:hover {
  background-image: url("../images/btns/hide-ad-on.svg");
}
.ic--trash {
  width: 52px;
  height: 52px;
  background-image: url("../images/btns/trash_active.svg");
}
.ic--trash.on, .ic--trash:hover {
  background-image: url("../images/btns/trash_over.svg");
}

.ad-list {
  overflow: hidden;
  padding-top: 1.25rem;
  padding-bottom: 5rem;
}
.ad-list.container {
  max-width: 1800px;
}

.ad {
  height: 100%;
  position: relative;
  padding-bottom: 0.625rem;
  background-color: #f6f6f6;
  border-radius: 0.4375rem;
  overflow: hidden;
}
.ad.business-card {
  position: relative;
  padding-bottom: 155px;
}
@media (max-width: 664px) {
  .ad {
    padding-bottom: 0;
  }
}
.ad .rating__stars {
  display: flex;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}
.ad--urgent {
  border: 5px solid #FFC400;
}
.ad > .columns {
  height: 100%;
  margin: 0;
  flex-flow: column nowrap;
}
@media (max-width: 768px) {
  .ad > .columns {
    flex-flow: row wrap;
  }
}
.ad > .columns .column {
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
@media (max-width: 768px) {
  .ad > .columns .column {
    padding: 0 0.625rem;
  }
}
.ad > .columns .column.blk4 {
  margin-top: auto;
}
.ad > .columns .column--wide {
  padding: 0 0.625rem;
}
.ad > .columns .column--nowide {
  padding: 0;
}
.ad__title {
  margin: 0;
  font-size: 1.875rem;
  line-height: 2.0625rem;
  font-weight: 700;
  color: #0080C3;
  overflow-wrap: anywhere;
  text-transform: uppercase;
}
@media (max-width: 664px) {
  .ad__title {
    font-size: 1.125rem;
  }
}
.ad__title > a {
  transition: color 0.16s ease-in-out;
}
.ad__title > a:hover {
  color: #000;
}
.ad__img-bg {
  height: 160px;
  background-size: cover;
  background-position: 50%;
}
.ad__needs {
  font-size: 0.75rem;
  color: #0080C3;
}
.ad__description {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  margin-bottom: 0.625rem;
  /* word-break: break-all; */
}
.ad__end {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  align-items: end;
  padding: 0.9375rem 1.25rem 1.25rem;
  flex-flow: row wrap;
  margin-left: 0;
  margin-right: 0;
}
.ad__end .column {
  margin-bottom: 0 !important;
}
.ad__end .column--left {
  padding: 0rem 0rem 0rem 0rem !important;
}
.ad__end .column--rigth {
  padding: 0rem 0rem 0rem 0rem !important;
}
.ad__end .business__name--box {
  border-top: 1px solid #0080C3;
  padding: 0 !important;
  width: 100%;
  flex: none;
  min-height: 1px;
  display: flex;
  align-items: center;
}
.ad__end .business__name--box.offer-status {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.ad__end .business__name--box span {
  font-weight: 400;
  text-transform: uppercase;
  color: #0080C3;
  font-size: 0.8125rem;
  display: inline-block;
}
.ad__end h3 {
  margin-bottom: 0;
  padding-top: 10px;
}
.ad__business {
  margin-top: 0.625rem;
  font-size: 0.875rem;
  line-height: 1;
}
@media (max-width: 768px) {
  .ad__business {
    margin-top: 0.375rem;
  }
}
@media (max-width: 664px) {
  .ad__business {
    font-size: 0.75rem;
    line-height: 0.8;
  }
}
.ad__business .business__name {
  margin-bottom: 0.3125rem;
  font-size: 1rem;
  font-weight: 700;
}
.ad__business .business__name > a {
  transition: color 0.16s ease-in-out;
}
.ad__business .business__name > a:hover {
  color: #000;
}
@media (max-width: 664px) {
  .ad__business .business__rating {
    display: inline;
    margin-bottom: 20px;
  }
  .ad__business .business__location {
    display: inline-block;
  }
}
.ad__info-item {
  position: relative;
  padding-top: 0.1875rem;
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
  min-height: 24px;
  display: flex;
  align-items: center;
}
.ad__info-item strong {
  font-weight: 400;
  text-transform: uppercase;
  color: #0080C3;
  font-size: 0.8125rem;
  display: inline-block;
  margin-right: 0.4375rem;
}
.ad__info-item--product {
  padding-top: 0rem;
  padding-left: 0rem;
  margin-bottom: 0rem;
}
.ad__info-item--requirements {
  margin-top: 1.4rem;
  margin-bottom: 1rem;
  align-items: flex-start;
  flex-flow: column;
}
.ad__info-item--requirements .ad__info-item-img {
  margin-top: 2px;
}
.ad__info-item--requirements ul {
  margin: 10px 0;
  width: 100%;
}
.ad__info-item--requirements .requirements-tit {
  display: flex;
  align-items: center;
}
.ad__info-item-img {
  margin-right: 0.4375rem;
  width: 0.9375rem;
}
@media (max-width: 664px) {
  .ad__info-item-img {
    width: 18px;
    height: 18px;
  }
}
.ad__actions {
  padding-top: 0.3125rem;
  padding-bottom: 0.9375rem;
  border-bottom: 1px solid #0080C3;
  min-height: 4.3125rem;
}
@media (max-width: 768px) {
  .ad__actions {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.ad__actions--noborder {
  border: none;
}
.ad__ref {
  position: absolute;
  top: 60px;
  right: 10px;
  padding: 6px 10px;
  background-color: #000;
  color: #FFF;
}
@media (max-width: 664px) {
  .ad__ref {
    top: initial;
    bottom: 70px;
    right: 0;
  }
}
.ad__badge {
  position: absolute;
  top: -6px;
  right: 10px;
}
.ad__cta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.625rem;
}
@media (max-width: 768px) {
  .ad__cta {
    padding: 0.375rem 0.625rem;
    margin-left: auto;
  }
}
@media (max-width: 664px) {
  .ad__cta {
    font-size: 1rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.ad .location__icon {
  width: 1.0625rem;
  height: auto;
}

.ad--noimg {
  padding-bottom: 2rem;
}
.ad--noimg .blk5 {
  flex-grow: 0;
  margin-top: auto;
}
.ad--noimg .ad__actions {
  margin-top: 0.625rem;
}

@media (max-width: 768px) {
  .ad .blk1 {
    order: 1;
  }
  .ad .blk2 {
    order: 4;
  }
  .ad .blk3 {
    order: 2;
  }
  .ad .blk4 {
    order: 3;
  }
  .ad .blk5 {
    order: 5;
  }
}
.ad-tooltip__link {
  display: block;
  text-decoration: none;
  color: inherit;
}
.ad-tooltip__link:hover {
  text-decoration: underline;
}
.ad-tooltip__icon {
  position: relative;
  vertical-align: middle;
  top: -3px;
  margin-right: 3px;
}

.business__name--box {
  border-top: 1px solid #0080C3;
  padding: 0 !important;
  width: 100%;
  flex: none;
  min-height: 1px;
  display: flex;
  align-items: center;
}
.business__name--box.offer-status {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.business__name--box span {
  font-weight: 400;
  text-transform: uppercase;
  color: #0080C3;
  font-size: 0.8125rem;
  display: inline-block;
}

.business__logo-wrapper {
  width: 50px !important;
  margin-top: 0.5rem !important;
  margin-right: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  padding: 0 !important;
}
@media (max-width: 664px) {
  .business__logo-wrapper {
    display: none;
  }
}
.business__logo-wrapper img {
  display: block;
  height: 50px;
  border-radius: 100%;
}
@media (max-width: 768px) {
  .business__logo-wrapper {
    width: 30px;
  }
}

.business__name {
  color: #0080C3;
}
.business__name a {
  color: inherit;
  text-decoration: none;
}
.business__logo {
  width: 100%;
}
.business__location {
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.business__gallery {
  cursor: pointer;
  margin-top: 1.25rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.business__badge {
  display: inline-block;
  position: relative;
  top: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
}
.business__badge img {
  width: 1.25rem;
  margin-left: 5px;
}

.rating {
  font-style: normal;
  min-height: 29px;
}
.rating__stars {
  margin-right: 0.375rem;
  line-height: 1.1875rem;
  display: flex;
  align-items: center;
}
.rating__stars em {
  display: inline-block;
  margin-left: 0.3125rem;
  font-size: 0.875rem;
  line-height: 1.1875rem;
}
.rating__star {
  display: inline-block;
  position: relative;
  top: 0px;
  width: 1.1875rem;
  height: 1.1875rem;
  margin-right: 0.125rem;
}

.location {
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.location__icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;
  /*box-shadow: 0 0 10px #ccc;*/
  border-bottom: 3px solid #e6e6e6;
  background-color: #FFF;
}
.header__container {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__left {
  display: flex;
  align-items: center;
}
.header__left .menu-burguer {
  margin-left: 1.25rem;
}
.header__beta {
  position: absolute;
  color: white;
  background-color: #0080C3;
  font-size: 12px;
  top: 10px;
  left: 10px;
  z-index: 2000;
  padding-inline: 10px;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .header {
    height: 120px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .header__container {
    max-width: 100%;
    display: block;
    text-align: center;
  }
  .header__left {
    display: block;
    margin-bottom: 10px;
  }
  .header__right {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: #FFF;
    box-shadow: 0 -3px 3px #ccc;
  }
}
@media (max-width: 664px) {
  .header {
    height: 100px;
  }
  .header__beta {
    position: absolute;
    color: white;
    background-color: #0080C3;
    font-size: 9px;
    top: 6px;
    left: 6px;
    z-index: 2000;
    padding-inline: 5px;
    border-radius: 10px;
  }
}
@media (max-width: 664px) {
  .header__logo {
    width: 140px;
  }
}

.nav {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .nav {
    justify-content: space-evenly;
    max-width: 320px;
    margin: 0 auto;
  }
}
.nav .ic {
  width: 52px;
  height: 52px;
  position: relative;
}
.nav .ic.show-count::after {
  content: attr(data-notifications);
  position: absolute;
  top: 0;
  left: 100%;
  color: white;
  background-color: #ff4040;
  transform: translate(-60%, -30%);
  padding: 4px;
  width: 10px;
  height: 10px;
  font-size: 12px;
  border-radius: 100%;
  display: grid;
  text-align: center;
  place-content: center;
  font-style: normal;
  border: 2px solid white;
}
.nav .ic.show-warning {
  border: 1px solid #ff4040;
}
.nav .ic.show-warning::after {
  content: attr(data-notifications);
  position: absolute;
  top: 0;
  left: 100%;
  color: white;
  background-color: #ff4040;
  transform: translate(-60%, -30%);
  padding: 4px;
  width: 10px;
  height: 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 100%;
  display: grid;
  text-align: center;
  place-content: center;
  font-style: normal;
  border: 2px solid white;
}
.nav__add {
  display: flex;
  width: 52px;
  height: 52px;
  justify-content: center;
  align-items: center;
  background-image: url(../images/icon-add.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 16px auto;
}
@media (min-width: 1216px) {
  .nav__add {
    padding: 0.125rem 2.625rem 0.125rem 1.125rem;
    background-position: right 10px top 50%;
    width: initial;
    background-size: 16px auto;
  }
}
@media (max-width: 1215px) {
  .nav__add {
    background-image: url(../images/btns/icon-add-01.svg);
    background-size: 52px auto;
  }
  .nav__add:hover {
    background-image: url(../images/btns/icon-add-01-on.svg);
  }
}
.nav__add-text {
  display: none;
}
@media (min-width: 1216px) {
  .nav__add-text {
    display: inline;
  }
}

.search-bar {
  overflow: hidden;
  display: flex;
  height: 52px;
  flex-flow: row nowrap;
  align-items: stretch;
  border: 0;
}
@media (max-width: 664px) {
  .search-bar {
    height: 32px;
  }
}
@media (max-width: 768px) {
  .search-bar.active .search-bar__checks {
    padding: 0;
    max-width: 0;
    overflow: hidden;
  }
  .search-bar.active .search-bar__input {
    margin-left: 16px;
  }
}
.search-bar__checks {
  padding: 2px 1.125rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  transition: all 0.3s ease;
}
.search-bar__checks label {
  cursor: pointer;
  margin-right: 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1044px) {
  .search-bar__checks label {
    margin-right: 0.5rem;
    font-size: 0.75rem;
  }
}
.search-bar__checks label:last-child {
  margin-right: 0;
}
.search-bar__checks label input {
  margin: 0 6px 0 0;
}
.search-bar__input {
  display: block;
  border-top: 1px solid #0080C3;
  border-bottom: 1px solid #0080C3;
  border-right: none;
  border-left: 1px solid #0080C3;
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
  padding: 2px 0.75rem;
  font-size: 1rem;
}
@media only screen and (min-width: 1517px) {
  .search-bar__input {
    min-width: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1450px) {
  .search-bar__input {
    max-width: 120px;
  }
}
@media (max-width: 1044px) {
  .search-bar__input {
    max-width: 100px;
  }
}
@media (max-width: 768px) {
  .search-bar__input {
    width: 100%;
    max-width: 100%;
  }
}
.search-bar__input:focus-visible {
  outline: none !important;
}
.search-bar__btnbox {
  border-top: 1px solid #0080C3;
  border-bottom: 1px solid #0080C3;
  border-right: 1px solid #0080C3;
  border-left: none;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}
.search-bar__btn {
  cursor: pointer;
  background-image: url(../images/icon-search.svg);
  background-repeat: no-repeat;
  background-position: right 50% top 50%;
  padding: 3px 22px;
  margin: 0;
  background-size: 16px auto;
  height: 50px;
}
@media (max-width: 664px) {
  .search-bar__btn {
    height: 30px;
  }
}
@media (min-width: 1216px) {
  .search-bar__btn {
    padding: 5px 2.875rem 5px 1.75rem;
    background-position: right 10px top 50%;
  }
}
.search-bar__btn-text {
  display: none;
}
@media (min-width: 1216px) {
  .search-bar__btn-text {
    display: inline;
  }
}

.menu-burguer {
  display: inline-block;
  width: 1.75rem;
  height: 1.25rem;
  background-image: url(../images/icon-menu.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.burguermenu {
  list-style: none;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.burguermenu li {
  padding-bottom: 0.9375rem;
  margin-bottom: 0.9375rem;
  border-bottom: 1px solid #0080C3;
}
.burguermenu li a:link, .burguermenu li a:visited, .burguermenu li a:active, .burguermenu li a:focus {
  font-size: 1.375rem;
  color: #1d1d1b;
}
.burguermenu__lilast {
  border-bottom: none !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.tooltip_templates {
  display: none;
}

.tooltipster-base {
  z-index: 99999 !important;
}

.tooltipster-content {
  padding: 0 !important;
}

.tooltipster-arrow-background {
  top: 0 !important;
}

.tooltipster-black .tooltipster-arrow-border,
.tooltipster-black .tooltipster-arrow-background {
  border-top-color: #000 !important;
  border-bottom-color: #000 !important;
}
.tooltipster-black .tooltipster-box {
  border: 0;
  border-radius: 8px;
  background-color: #000;
}

.tooltipster-white .tooltipster-arrow-border {
  border-top-color: rgba(0, 0, 0, 0.5) !important;
  border-bottom-color: rgba(0, 0, 0, 0.5) !important;
}
.tooltipster-white .tooltipster-arrow-background {
  border-top-color: #FFF !important;
  border-bottom-color: #FFF !important;
  top: 0 !important;
}
.tooltipster-white .tooltipster-box {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 8px;
  background-color: #FFF;
}
.tooltipster-white .tooltipster-content {
  color: #000;
}

.tooltip {
  padding: 1.125rem 1.25rem 1rem;
}
.tooltip__profile-nav {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
}
.tooltip__profile-nav-link {
  display: block;
  text-decoration: none;
  color: #FFF;
  margin-bottom: 0.25rem;
}
.tooltip__profile-nav-link:last-child {
  margin-bottom: 0rem;
}
.tooltip__profile-nav-link:hover {
  text-decoration: underline;
}

.tooltip-center {
  padding: 0 !important;
}

.tooltip-alert {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  font-size: 0.875rem;
  color: #000;
  border-bottom: 1px solid #f7f7f7;
  transition: background-color 160ms ease-in-out;
}
.tooltip-alert:hover {
  background-color: #f7f7f7;
}
.tooltip-alert:active, .tooltip-alert:focus-within {
  background-color: #dbdbdb;
}
.tooltip-alert__content {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  flex-grow: 1;
  width: 100%;
  cursor: pointer;
}
.tooltip-alert__content button[type=submit] {
  background: transparent;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0;
}
.tooltip-alert__content .tooltip-alert__logo {
  max-width: 38px;
  flex-shrink: 0;
}
.tooltip-alert__content .tooltip-alert__logo img {
  width: 38px;
  height: 38px;
  border-radius: 999px;
  border: 1px solid #0080C3;
}
.tooltip-alert__dismiss {
  margin-left: auto;
  flex-shrink: 0;
  cursor: pointer;
}
.tooltip-alert a {
  color: inherit;
}

.tooltip-cta {
  display: block;
  padding: 1rem 0.75rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  color: #FFF;
  background-color: #0080C3;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: background-color 160ms ease-in-out;
}
.tooltip-cta:hover {
  background-color: rgba(0, 128, 195, 0.9);
}

.tooltip-notifications {
  max-height: 300px;
  overflow: auto;
}

.tooltip-filters {
  margin: 0 !important;
  padding: 0.375rem 0.625rem;
  background-color: #efefef;
}
.tooltip-filters .is-12 {
  padding-bottom: 0 !important;
}
.tooltip-filters__img {
  display: block;
  margin: 0 auto;
  width: 55px;
}
.tooltip-filters .column {
  padding: 0.25rem;
}

.fancybox-content {
  padding: 3.125rem 1.875rem;
}

.fancybox-container .fancybox-slide {
  padding: 0.625rem;
}
.fancybox-container .fancybox-content {
  max-width: 95%;
  max-height: 95%;
}

.popup-iframe {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  background-color: #fff;
  max-width: 800px;
}
.popup-iframe-close {
  position: fixed;
  top: 0.625rem;
  right: 0.625rem;
  padding: 0.625rem;
  cursor: pointer;
}

.filters-box {
  display: flex;
  flex-flow: row wrap;
}

.filters-tabs {
  flex: 0 0 50%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 3.4375rem;
  line-height: 3.4375rem;
  color: #0080C3;
  font-family: "Attila_Sans", sans-serif;
  font-weight: 100;
}
@media (max-width: 664px) {
  .filters-tabs {
    flex: 0 0 100%;
    font-size: 1.75rem;
  }
}
.filters-tabs__link {
  font-family: "Attila_Sans", sans-serif;
  font-size: 3.4375rem;
  line-height: 3.4375rem;
  font-weight: 400;
  position: relative;
  cursor: pointer;
}
@media (max-width: 664px) {
  .filters-tabs__link {
    font-size: 1.75rem;
  }
}
.filters-tabs__link--select span {
  opacity: 0.2;
}
.filters-tabs__link:first-child {
  padding-right: 1.5rem;
  margin-right: 1.5rem;
}
.filters-tabs__link:first-child::after {
  content: "";
  border-right: 3px solid #0080C3;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
  width: 1px;
  height: 50px;
}

.filters {
  flex: 0 0 50%;
  position: relative;
  font-size: 0.875rem;
  padding-top: 2.5rem;
  padding-bottom: 1.25rem;
  display: flex;
  justify-content: right;
}
@media (max-width: 664px) {
  .filters {
    justify-content: left;
  }
}
@media (max-width: 1044px) {
  .filters__main {
    padding-right: 0;
  }
}
.filters__tgl {
  padding: 0.25rem 0.625rem;
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  font-weight: 400;
  font-size: 0.875rem;
  background: #FFF;
  cursor: pointer;
  color: #000;
}
.filters__tgl:hover {
  color: #000;
  background-color: #FFF;
}
@media (min-width: 1044px) {
  .filters__tgl {
    display: none;
  }
}
.filters__content {
  display: none;
}
@media (max-width: 1044px) {
  .filters__content {
    padding-top: 1.25rem;
  }
}
@media (min-width: 1044px) {
  .filters__content {
    display: flex !important;
    align-items: center;
  }
}
.filters__content.active {
  display: block;
}
.filters__info-btn {
  cursor: pointer;
  padding: 4px 6px;
  margin-left: 5px;
}
.filters__input {
  margin-left: 10px;
  width: auto;
  height: 52px;
  padding: 0.375rem 0.625rem;
  border: 1px solid #0080C3;
  border-radius: 9999rem !important;
  font-size: 1.125rem;
  background-color: #FFF;
  -webkit-background-color: #FFF;
  color: #0080C3;
  background-image: url(../images/icon-search-blue.svg);
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 16px auto;
}
@media (max-width: 664px) {
  .filters__input {
    height: 32px;
  }
}
.filters__input:focus-visible {
  outline: none !important;
}
.filters__select {
  height: 52px;
  padding: 0.375rem 0.625rem;
  border: 1px solid #0080C3;
  border-radius: 9999rem;
  font-size: 1.125rem;
  background: #FFF;
  -webkit-background: #FFF;
  cursor: pointer;
  color: #0080C3;
}
@media (max-width: 664px) {
  .filters__select {
    height: 32px;
  }
}
@media only screen and (min-width: 1551px) {
  .filters__select {
    width: 260px;
  }
}
@media only screen and (max-width: 1550px) {
  .filters__select {
    width: 180px;
  }
}
.filters__geoloc {
  margin-left: 2.5rem;
  display: inline-flex;
  align-items: center;
}
@media (max-width: 664px) {
  .filters__geoloc {
    display: block;
    margin-right: 0;
  }
}
@media (max-width: 1044px) {
  .filters__geoloc {
    margin-bottom: 10px;
  }
}
.filters__geoloc input {
  margin: 0;
}
.filters__geoloc-label {
  margin-left: 0.375rem;
  margin-right: 1rem;
  font-size: 1.125rem;
}
.filters__sort-label {
  margin-right: 0.75rem;
  font-size: 1.125rem;
}
@media (max-width: 380px) {
  .filters__sort-label {
    display: none;
  }
}
.filters__right {
  text-align: right;
}
@media (max-width: 380px) {
  .filters__right {
    width: 160px;
  }
}
.filters__btn {
  height: 52px;
  padding: 0.375rem 0.625rem;
  border: 1px solid #0080C3;
  border-radius: 9999rem;
  background: #FFF;
  cursor: pointer;
  color: #0080C3;
  font-size: 1.125rem;
}
.filters__btn:hover {
  color: #FFF;
  background-color: #0080C3;
}
.filters__btn--geoloc {
  padding-left: 1.75rem;
  background-image: url(../images/icon-geolocation.svg);
  background-position: left 4px top 50%;
  background-size: 22px 18px;
  background-repeat: no-repeat;
}

.button-group {
  display: inline-flex;
  flex-flow: row nowrap;
}
.button-group button {
  border-radius: 0;
  display: block;
}
.button-group button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.button-group button:not(:first-child) {
  margin-left: -2px;
}
.button-group button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tooltip-filters {
  width: 100%;
  font-size: 14px;
}

.form hr {
  margin-bottom: 1rem;
}
.form .title {
  margin-bottom: 0;
  font-family: "Attila_Sans", sans-serif;
  color: #0080C3;
  font-weight: 400;
}
.form__field {
  position: relative;
  margin-bottom: 1.25rem;
}
.form__field.error .input {
  border-color: red;
}
.form__field.expanded {
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  max-width: 90%;
  max-height: 80%;
  margin-bottom: 0;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
  padding: 20px;
  background-color: #FFF;
}
.form__field.expanded .textarea {
  height: 380px;
}
.form__field.expanded .form__expand {
  top: 10px;
  right: 10px;
}
.form__field__cl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form__label {
  display: block;
  margin-bottom: 0.625rem;
}
.form__label-info {
  margin-left: 6px;
  cursor: pointer;
  vertical-align: middle;
}
.form__expand {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 10px;
  cursor: pointer;
}
.form__button {
  margin-top: 0.625rem;
  width: 200px;
}
.form .column {
  padding-top: 0;
  padding-bottom: 0;
}

.showpass {
  padding-left: 25px;
  background-image: url(../images/icon-show-pass.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 1.25rem auto;
  color: #0080C3;
  font-weight: bold;
  margin-bottom: 0.625rem;
}

.input {
  width: 100%;
  height: 36px;
  border-radius: 3px;
  border: 1px solid #0080C3;
  padding: 0.1875rem 0.5rem;
  background: #FFF;
  font-size: 16px;
}
.input[type=file] {
  padding: 0.375rem;
}
.input:is(input):not(:focus):not(:placeholder-shown):invalid {
  border-color: #B00020;
}
.input:is(input):not(:focus):not(:placeholder-shown):invalid + .input__error {
  display: block;
}
.input__error {
  display: none;
  color: #B00020;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}
.input__error .material-icons {
  color: #B00020;
  margin-right: 0.3rem;
}

.textarea {
  display: block;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #0080C3;
  padding: 0.1875rem 0.5rem;
  background: #FFF;
  font-size: 16px;
}

@media (max-width: 768px) {
  .opt-wrapper {
    display: block;
  }
}

.str-text {
  font-style: normal;
  color: #4a4a4a;
}

.tag-businesses {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
}
.tag-businesses:hover {
  text-decoration: underline;
}
.tag-businesses:hover .ic--businesses-send {
  background-image: url(../images/btns/businesses-send-on.svg);
  transform: scale(1.05);
}

.hide-businesses {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
}
.hide-businesses:hover {
  text-decoration: underline;
}
.hide-businesses:hover .ic--hide-ad {
  background-image: url(../images/btns/hide-ad-on.svg);
  transform: scale(1.05);
}

::placeholder {
  font-style: normal;
  color: #4a4a4a;
  opacity: 1;
}

:-ms-input-placeholder {
  font-style: normal;
  color: #4a4a4a;
}

::-ms-input-placeholder {
  font-style: normal;
  color: #4a4a4a;
}

.event-notice-new {
  color: green;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding: 1rem;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.tabs__tab {
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem 1.5rem 0.5rem 0rem;
  font-family: "Attila_Sans", sans-serif;
  font-weight: 400;
  font-size: 2rem;
  color: #0080C3;
  position: relative;
  opacity: 0.2;
}
@media (max-width: 664px) {
  .tabs__tab {
    font-size: 1.375rem;
  }
}
.tabs__tab:hover {
  opacity: 1;
}
.tabs__tab.active {
  opacity: 1;
}
.tabs__tab.active span {
  opacity: 1;
}
.tabs__tab:first-child {
  margin-right: 1.5rem;
}
.tabs__tab:first-child::after {
  content: "";
  border-right: 1px solid #0080C3;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
  width: 1px;
  height: 40px;
}
.tabs--modal {
  padding-left: 0;
}
.tabs--modal .tabs__tab {
  font-size: 1.125rem;
}

.tabs-content {
  padding: 1.875rem 1.25rem 2.5rem 0;
}
@media (max-width: 664px) {
  .tabs-content {
    padding: 1.25rem 0rem;
  }
}

.tab {
  display: none;
}
.tab.active {
  display: block;
}

.chat-window {
  --header-height: 65px;
  position: fixed;
  bottom: 0;
  right: 5rem;
  height: 500px;
  width: 350px;
  display: flex;
  flex-direction: column;
  background-color: #999;
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;
  z-index: 100;
  transition: transform 200ms ease-in-out;
  transform: translateY(calc(100% - var(--header-height)));
}
@media (width < 664px) {
  .chat-window {
    margin-bottom: 73px;
    right: 1rem;
  }
}
.chat-window.open {
  transform: translateY(0);
  border: 1px solid rgba(216, 216, 216, 0.9);
}
.chat-window.open .chats-screen {
  overflow: auto;
}
.chat-window.open .chat-window__chevron {
  transform: rotate(0turn);
}
.chat-window__header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  background-color: rgba(216, 216, 216, 0.9);
  padding: 1rem;
  font-weight: 800;
  cursor: pointer;
}
.chat-window__chevron {
  transform: rotate(0.5turn);
  margin-left: auto;
  cursor: pointer;
}
.chat-window__chevron.invert {
  transform: rotate(0turn);
}
.chat-window .chats-screen {
  height: 100%;
  background-color: white;
  flex-grow: 1;
}
.chat-window .chats-screen.empty {
  display: grid;
  place-items: center;
}
.chat-window .chats-screen__header {
  position: sticky;
  top: 0;
  height: var(--header-height);
  background-color: rgba(216, 216, 216, 0.9);
  padding: 1rem;
  font-weight: 800;
  cursor: pointer;
}
.chat-window .chats-screen .chat-preview {
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
}
.chat-window .chats-screen .chat-preview .chat-info {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "company time" "message notifications";
}
.chat-window .chats-screen .chat-preview .chat-info__title {
  grid-area: "company";
  font-weight: 700;
}
.chat-window .chats-screen .chat-preview .chat-info__message {
  grid-area: "message";
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.chat-window .chats-screen .chat-preview .chat-info__time {
  grid-area: "time";
  font-size: 0.9rem;
  opacity: 0.9;
}
.chat-window .chats-screen .chat-preview .chat-info__notifications {
  grid-area: "notifications";
  display: grid;
  place-items: center;
  margin: auto;
  width: 1.4rem;
  height: 1.4rem;
  font-size: 0.8rem;
  border-radius: 50%;
  background-color: #0080C3;
  color: white;
  font-style: normal;
}
.chat-window .chats-screen .chat-preview .chat-info__notifications.hide {
  display: none;
}
.chat-window .chat-list {
  background-color: white;
  user-select: none;
}
.chat-window .chat-list__entry {
  padding: 1rem;
  background-color: white;
  transition: 200ms ease;
}
.chat-window .chat-list__entry:hover {
  background-color: #e2e2e2;
}
.chat-window .chat-list__entry:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}
.chat-window .user-logo {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  border: 1px solid #0080C3;
}
.chat-window .user-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chat-window .chat {
  display: none;
}
.chat-window .chat.active {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
  z-index: 10;
}
.chat-window .chat__header {
  background-color: #e6e6e6;
  padding: 0.5rem;
  display: flex;
  column-gap: 0.4rem;
  align-items: center;
}
.chat-window .chat__header .show-profile {
  margin-left: auto;
}
.chat-window .chat__header .button {
  font-size: 0.9rem;
  padding: 0.25rem 0.75rem;
}
.chat-window .chat__header .chat-navigate-back {
  transform: rotate(0.25turn);
  cursor: pointer;
}
.chat-window .chat__body {
  background-color: white;
  flex-grow: 1;
  border: 2px solid rgba(216, 216, 216, 0.9);
  overflow-y: auto;
  overscroll-behavior: contain;
}
.chat-window .chat__body.loading {
  display: grid;
  place-content: center;
}
.chat-window .chat__body .chat-messages {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 1rem;
}
.chat-window .chat__body .chat-messages .message {
  display: flex;
  flex-direction: column;
  padding: 0.35rem 1rem;
  border-radius: 1.2rem;
  background-color: #999999;
  color: white;
  font-weight: bold;
  width: fit-content;
  max-width: 75%;
  transition: background-color 200ms ease;
  font-size: 0.9rem;
}
.chat-window .chat__body .chat-messages .message .time {
  margin-left: auto;
  font-size: 0.7rem;
  opacity: 0.75;
}
.chat-window .chat__body .chat-messages .message.loading {
  animation: message-skeleton 500ms ease infinite alternate;
}
@keyframes message-skeleton {
  from {
    background-color: #999999;
  }
  to {
    background-color: #bfbfbf;
  }
}
.chat-window .chat__body .chat-messages .message--left {
  background-color: #1dc91d;
  margin-right: auto;
}
.chat-window .chat__body .chat-messages .message--right {
  background-color: #3c3cdd;
  margin-left: auto;
  justify-content: end;
}
.chat-window .chat__body .chat-messages .message details summary {
  margin-top: 0.4rem;
  cursor: pointer;
  opacity: 0.8;
}
.chat-window .chat__body .chat-messages .message details summary:hover {
  text-decoration: underline;
}
.chat-window .chat__body .chat-messages .message-filelist {
  border-radius: 4px;
  background-color: rgba(216, 216, 216, 0.9);
  padding: 0.6rem;
  margin-block: 0.4rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
}
.chat-window .chat__body .chat-messages .message-filelist li:hover a {
  text-decoration: underline;
}
.chat-window .chat__body .chat-messages .message-filelist a {
  color: black;
}
.chat-window .chat__footer {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  padding: 1rem;
  background-color: rgba(216, 216, 216, 0.9);
}
.chat-window .chat__footer .chat__input {
  flex-grow: 1;
  border: 1px solid #0080C3;
  border-radius: 0.3rem;
  padding: 0.5rem;
}
.chat-window .chat__footer .send-icon {
  flex-basis: 35px;
  height: 100%;
  cursor: pointer;
  color: #0080C3;
}
.chat-window .chat__footer .attachment-icon {
  transform: rotate(270deg);
  color: #0080C3;
  cursor: pointer;
}
.chat-window .chat__footer .message-attachments {
  position: absolute;
  inset: auto 0 100% 0;
  padding: 0.7rem 1.4rem;
  background-color: whitesmoke;
  border-top: 1px solid #0080C3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-window .chat__footer .message-attachments small {
  flex-shrink: 0;
}

.mySwiper {
  max-width: 700px;
  height: 100%;
}

.swiper-slide {
  background-color: grey;
  height: auto !important;
  text-align: center;
}

.swiper-content {
  height: 100% !important;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  opacity: 1;
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-bullet-active {
  background: white;
}

.swiper-slide-bg {
  width: 100%;
  padding-bottom: 70%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.business-card {
  /*background-color: $white;*/
}
.business-card__bg {
  display: block;
  margin-bottom: 1.875rem;
  position: relative;
  padding-bottom: 56%;
  background-position: 50%;
  background-size: cover;
  background-color: #0080C3;
}
.business-card__logo {
  position: absolute;
  bottom: -20px;
  left: 16px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 4px solid #FFF;
  background-color: white;
  overflow: hidden;
}
.business-card__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.business-card__btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.business-card .location {
  margin-top: 15px;
}
.business-card__content {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  position: relative;
}
.business-card__bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  height: 155px;
}
.business-card__name {
  margin-bottom: 0.375rem;
  font-size: 1.25rem;
  color: #000;
  text-transform: uppercase;
}
.business-card__name > a {
  font-weight: 500;
}
.business-card__name .profile__badge {
  top: 3px;
}
.business-card__name .profile__badge img {
  width: 20px;
  margin-left: 5px;
}
.business-card__intro {
  margin-bottom: 0.875rem;
}
.business-card__stats {
  margin-bottom: 0.875rem;
  font-size: 0.8125rem;
}

.profile-content .fab {
  position: fixed;
  bottom: 1rem;
  right: 1.5rem;
  border-radius: 100%;
  background-color: #0080C3;
  padding: 1rem;
  z-index: 3000;
  cursor: pointer;
  transition: transform 120ms ease;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 63px;
  height: 63px;
}
.profile-content .fab img {
  width: 100%;
}
.profile-content .fab:hover, .profile-content .fab:focus-visible {
  transform: scale(1.1);
}
.profile-content .hover-darken {
  cursor: pointer;
  transition: filter 200ms ease;
}
.profile-content .hover-darken:hover {
  filter: brightness(50%);
}

.profile {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  min-height: calc(100vh - 82px);
}
.profile.container {
  max-width: 1600px;
  padding-top: 2.5rem;
}
.profile__main {
  flex: 0 1 100%;
  width: 100%;
  transition: all 300ms ease-in-out;
  border-top-left-radius: 0.4375rem;
  overflow: hidden;
}
.profile__side {
  position: relative;
  flex: 0 0 360px;
  width: 360px;
  padding: 2.5rem 1.875rem;
  background-color: #EBEBEB;
  transition: all 300ms ease-in-out;
  border-top-right-radius: 0.4375rem;
}
@media (max-width: 1216px) {
  .profile__side {
    flex: 0 0 260px;
    width: 260px;
    padding: 2.5rem 1.25rem;
  }
}
@media (max-width: 1044px) {
  .profile__side {
    z-index: 1100;
    position: fixed;
    top: 82px;
    right: 0;
    bottom: 0;
    transform: translateX(360px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 10px 10px #333;
    font-size: 0.875rem;
  }
}
@media (max-width: 768px) {
  .profile__side {
    top: 120px;
  }
}
@media (max-width: 664px) {
  .profile__side {
    top: 100px;
  }
}
.profile__side-content .rating {
  font-size: 1.25rem;
  margin-bottom: 0.375rem;
}
.profile__side-content .rating__star {
  width: 22px;
  height: 22px;
}
.profile__side-content .business__location {
  font-size: 1rem;
  margin-bottom: 0.375rem;
}
.profile__side-content .business__location a {
  color: #000;
}
.profile__side-content .business__name {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  color: inherit;
}
.profile__side-content .business__services ul {
  list-style: none;
  padding-left: 0;
}
.profile__side-content .business__services ul li {
  font-size: 1rem;
}
.profile__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 20px;
  font-size: 0.875rem;
  cursor: pointer;
}
@media (min-width: 1044px) {
  .profile__close {
    display: none;
  }
}
.profile__side-arrow {
  position: absolute;
  top: 80px;
  left: -60px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-right-width: 40px;
  border-right-color: #EBEBEB;
}
@media (max-width: 1216px) {
  .profile__side-arrow {
    display: none;
  }
}
.profile__header {
  position: relative;
  padding: 240px 20px 20px;
  background-color: #0080C3;
}
@media (max-width: 1044px) {
  .profile__header {
    padding: 180px 0 10px;
  }
}
@media (max-width: 664px) {
  .profile__header {
    padding: 100px 0 10px;
  }
}
.profile__header .container {
  padding-right: 10px;
}
.profile__header .is-narrow {
  width: 200px;
}
@media (max-width: 1044px) {
  .profile__header .is-narrow {
    width: 100px;
  }
}
.profile__header .ic {
  width: 52px;
  height: 52px;
}
.profile__image {
  overflow: hidden;
  position: absolute;
  bottom: -20px;
  width: 210px;
  height: 210px;
  border: 5px solid #f7f7f7;
  border-radius: 100%;
  background-color: #0080C3;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1044px) {
  .profile__image {
    width: 100px;
    height: 100px;
  }
}
.profile__image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile__content {
  margin-top: 5rem;
}
@media (max-width: 768px) {
  .profile__content {
    margin-top: 1.25rem;
  }
}
.profile__content .container {
  padding-right: 0;
  padding-left: 0;
}
.profile__name {
  display: inline-block;
  margin-bottom: 0.25rem;
  margin-right: 0.375rem;
  font-family: "Attila_Sans", sans-serif;
  font-size: 2.125rem;
  line-height: 2.125rem;
  font-weight: normal;
  color: #0080C3;
}
.profile__tit {
  font-size: 0.875rem;
  font-weight: normal;
  color: #0080C3;
  margin-bottom: 0.125rem;
}
.profile__tit--accordion {
  cursor: pointer;
  width: fit-content;
  padding-right: 1.375rem;
  background-image: url("../images/icon-accordion.svg");
  background-repeat: no-repeat;
  background-position: 97% center;
  background-size: 0.8125rem auto;
}
.profile__badge {
  display: inline-block;
  position: relative;
  top: 4px;
  width: 2.3125rem;
  height: 2.3125rem;
}
.profile__collab {
  font-size: 1.25rem;
  margin-bottom: 0.625rem;
}
.profile__text {
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
  font-size: 0.875rem;
  max-height: 325px;
  overflow-y: auto;
}
.profile__text-hidden {
  margin-top: 1rem;
  display: none;
}
.profile__opinions-title {
  font-size: 1.25rem;
  margin-bottom: 0.375rem;
}
.profile__images {
  margin-bottom: 10px;
}
.profile__images img {
  display: block;
  width: 100%;
}
.profile__images .columns {
  margin: 0;
}
.profile__images .column {
  padding: 3px;
}
.profile .tabs-select {
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
@media (min-width: 769px) {
  .profile .tabs-select {
    display: none;
  }
}
@media (max-width: 768px) {
  .profile .tabs-select {
    padding: 1.25rem 1.25rem 0;
  }
}
@media (max-width: 664px) {
  .profile .tabs-select {
    padding: 1.25rem 0 0;
  }
}
.profile .tabs-select select {
  margin-left: 10px;
}
@media (max-width: 768px) {
  .profile .tabs-content {
    padding-top: 0;
  }
}
.profile__button {
  margin-bottom: 1.5rem;
}

.opinion {
  margin-top: 1rem;
}
.opinion__date {
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
}
.opinion__text {
  font-size: 0.875rem;
}
.opinion .rating {
  margin-bottom: 0;
}
.opinion .rating__star {
  width: 18px;
  height: 18px;
}

.profile-sidebtn {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  transition: none;
}
.profile-sidebtn.active {
  transform: rotate(-180deg);
}
@media (max-width: 1044px) {
  .profile-sidebtn {
    top: 10px;
    right: 20px;
  }
}

.profile.collapsed {
  overflow: hidden;
}
.profile.collapsed .profile__main {
  flex: 0 0 100%;
}
@media (min-width: 1044px) {
  .profile.collapsed .profile__side {
    flex: 0 0 0%;
    overflow: hidden;
  }
  .profile.collapsed .profile__side .profile__side-content {
    opacity: 0;
  }
}
@media (max-width: 1044px) {
  .profile.collapsed .profile__side {
    transform: translateX(0);
  }
}
@media (min-width: 1044px) {
  .profile.collapsed .is-4-desktop {
    width: 25%;
  }
}

@media (min-width: 768px) {
  .profile-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.875rem;
  }
}
.profile-filters__filter {
  margin-bottom: 0.625rem;
}
.profile-filters__filter select {
  margin-left: 0.625rem;
}

.services-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0;
}
.services-list__item {
  margin-block: 0.3rem;
  transition: all 100ms ease;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}
.services-list__item .handle {
  width: 25px;
  height: 25px;
  background-image: url("../images/icon-drag_handle.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: move;
}
.services-list__item .delete {
  width: 20px;
  height: 20px;
  background-image: url("../images/action/trash.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.show__badge {
  display: inline-block;
  position: relative;
  top: 0;
  width: 1.25rem;
  height: 1.25rem;
}
.show__badge img {
  width: 1.25rem;
  margin-left: 5px;
}

.product .container {
  max-width: 1800px;
}
.product .container--box {
  margin: 0 auto;
}
.product-box {
  background-color: #f6f6f6;
  border-radius: 0.4375rem;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
}
@media (min-width: 768px) {
  .product-box {
    margin: 0 auto;
    padding: 1.875rem 3.125rem 3.125rem 3.125rem;
  }
}
@media (max-width: 768px) {
  .product-box {
    margin-bottom: 75px;
  }
}
.product-box .swiper {
  border-radius: 0.4375rem;
}
.product .is-12-mobile .ad__info-item img {
  width: 2rem;
  height: auto;
}
.product__name {
  font-size: 2.25rem;
  margin-bottom: 0;
  overflow-wrap: anywhere;
  color: #0080C3;
}
@media (min-width: 768px) {
  .product__name {
    font-size: 2.875rem;
    line-height: 2.875rem;
    margin-bottom: 0.9375rem;
  }
}
.product__description {
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
}
.product__meta {
  padding: 1.25rem;
  background-color: #f7f7f7;
}
.product__top {
  padding-top: 1.25rem;
}
.product__img-wrapper {
  margin-bottom: 1.25rem;
}
.product__img {
  width: 100%;
  display: block;
}
.product__business {
  font-size: 0.875rem;
}
.product__business .business__name {
  font-size: 1rem;
  margin-bottom: 0;
}
.product__business .business__location {
  margin-bottom: 0.375rem;
}

.product-header {
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .product-header__right {
    text-align: right;
  }
}

.product-btns {
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .product-btns {
    text-align: right;
    margin-bottom: -1.25rem;
    min-height: 58px;
  }
  .product-btns.no-docs {
    margin-top: 0;
    margin-bottom: 1.25rem;
  }
}

.product-actions img {
  opacity: 0.3;
}
.product-actions img:hover {
  opacity: 1;
}

.product-docs {
  margin-bottom: 2.25rem;
}
.product-docs__title h2 {
  display: inline-block;
  margin-right: 0.75rem;
  margin-bottom: 0;
  font-size: 1.125rem;
}
.product-docs__title img {
  position: relative;
  top: 8px;
  vertical-align: super;
}
.product-docs__link {
  color: #0080C3;
  text-transform: uppercase;
  background-image: url(../images/download-bt.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 52px auto;
  padding-top: 20px;
  padding-right: 70px;
  padding-bottom: 20px;
  font-size: 1.375rem;
}
.product-docs__link:hover {
  background-image: url(../images/download-bt-on.svg);
}
.product-docs__doc {
  display: flex;
  align-items: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #E0E0E0;
}
.product-docs__doc:hover {
  background-color: #f7f7f7;
}
.product-docs__type {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.product-docs__type img {
  display: block;
}
.product-docs__name {
  color: #000;
}

.product-offers {
  font-size: 0.875rem;
}
.product-offers .table-wrapper {
  overflow-x: auto;
}
.product-offers table {
  table-layout: fixed;
  border-collapse: collapse;
}
@media (min-width: 768px) {
  .product-offers table {
    width: 100%;
  }
}
.product-offers table thead {
  background-color: rgba(149, 149, 149, 0.2);
  text-align: left;
}
.product-offers table tr:nth-child(2n) {
  background-color: rgba(149, 149, 149, 0.1);
}
.product-offers table th, .product-offers table td {
  padding: 0.375rem 1rem;
}
.product-offers table .rating {
  font-style: italic;
  font-size: 0.75rem;
}
.product-offers table .rating__star {
  width: 13px;
  height: 13px;
}

.round {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  display: inline-block;
  background-color: red;
}
.round.green {
  background-color: #16BD00;
}

.offers .container--box {
  max-width: 1200px;
}

.offers-meta {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
@media (min-width: 768px) {
  .offers-meta {
    text-align: right;
  }
  .offers-meta .ad__info-item {
    display: inline-block;
    margin-right: 1.25rem;
  }
}

.is-job {
  position: relative;
  padding-bottom: 120px;
}
@media (max-width: 664px) {
  .is-job {
    padding-bottom: 170px;
  }
}

.message-filters {
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .message-filters {
    display: flex;
    align-items: center;
  }
}
.message-filters__left {
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .message-filters__left {
    margin-bottom: 0rem;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .message-filters__right {
    width: 50%;
    text-align: right;
  }
}
.message-filters__right .button:not(:last-child) {
  margin-right: 0.625rem;
}
@media (max-width: 664px) {
  .message-filters__right .button:not(:last-child) {
    margin-right: 0.25rem;
  }
}
@media (max-width: 664px) {
  .message-filters__right .text-btn {
    min-height: 32px;
    padding: 4px 20px;
  }
  .message-filters__right .ic {
    width: 32px;
    height: 32px;
  }
}
.message-filters__right .ic input {
  visibility: hidden;
}

.message-search {
  overflow: hidden;
  display: inline-flex;
  height: 40px;
  flex-flow: row nowrap;
  align-items: stretch;
  border: 0;
  border-radius: 9999px;
  box-shadow: 0 0 10px #ddd;
  background-color: #FFF;
}
@media (max-width: 664px) {
  .message-search {
    height: 32px;
  }
}
.message-search__input {
  display: block;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-left: 1px solid #eee;
  border-radius: 0;
  padding: 2px 0.75rem;
  font-size: 1rem;
}
.message-search__button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
  background-image: url(../images/icon-search.svg);
  background-repeat: no-repeat;
  background-position: left 50% top 50%;
  padding: 3px 22px;
  margin: 0;
}
@media (max-width: 768px) {
  .message-search__button {
    border-radius: 0;
  }
}
@media (min-width: 1216px) {
  .message-search__button {
    padding: 7px 1.75rem 7px 2.875rem;
    background-position: left 12px top 50%;
    background-size: 32px 22px;
  }
}
.message-search__button-text {
  display: none;
}
@media (min-width: 1216px) {
  .message-search__button-text {
    display: inline;
  }
}

.message-folders-title {
  padding-top: 1.25rem;
  margin-bottom: 1.25rem;
  color: #0080C3;
  font-weight: 700;
  font-size: 1.125rem;
  border-top: 1px solid #E4E4E4;
}

.message-folders {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid #E4E4E4;
}
.message-folders__folder {
  padding: 0.625rem 0.375rem;
  border-bottom: 1px solid #E4E4E4;
  cursor: pointer;
}
.message-folders__folder:hover {
  background-color: #E4E4E4;
}

.message-add-folder {
  display: block;
  width: 100%;
  margin-top: 1.25rem;
  background-image: url(../images/icon-add.svg);
  background-position: 6px 50%;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  font-size: 0.875rem;
}

.notifications-page .notification {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid #E4E4E4;
  font-size: 1.125rem;
  color: #000;
}
.notifications-page .notification:first-child {
  border-top: 1px solid #E4E4E4;
}
.notifications-page .notification a {
  color: inherit;
}
.notifications-page .notification a:hover {
  text-decoration: underline;
}
.notifications-page .notification__left {
  padding-right: 0.625rem;
  min-width: 40px;
}
@media (max-width: 340px) {
  .notifications-page .notification__left {
    display: none;
  }
}
.notifications-page .notification__right {
  padding-right: 3.125rem;
}
.notifications-page .notification__title {
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
  font-weight: 300;
}
.notifications-page .notification__name {
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
}
.notifications-page .notification__time {
  position: absolute;
  top: 0.625rem;
  right: 0;
}
.notifications-page .notification__data {
  position: absolute;
  bottom: 0.625rem;
  right: 0;
  display: flex;
  align-items: center;
}
.notifications-page .notification__text {
  font-size: 0.875rem;
}
.notifications-page .notification__num {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  color: #FFF;
  background-color: #0080C3;
}
.notifications-page .notification.unread {
  font-weight: 700;
}
.notifications-page .notification.unread .notification__title {
  font-weight: inherit;
}
.notifications-page .notification-filters {
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .notifications-page .notification-filters {
    display: flex;
    align-items: center;
  }
}
.notifications-page .notification-filters .text-btn {
  line-height: 2.2rem;
}
.notifications-page .notification-filters__left {
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .notifications-page .notification-filters__left {
    margin-bottom: 0rem;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .notifications-page .notification-filters__right {
    width: 50%;
    text-align: right;
  }
}
.notifications-page .notification-filters__right .button:not(:last-child) {
  margin-right: 0.625rem;
}
@media (max-width: 664px) {
  .notifications-page .notification-filters__right .button:not(:last-child) {
    margin-right: 0.25rem;
  }
}
@media (max-width: 664px) {
  .notifications-page .notification-filters__right .text-btn {
    min-height: 32px;
    padding: 4px 20px;
  }
  .notifications-page .notification-filters__right .ic {
    width: 32px;
    height: 32px;
  }
}
.notifications-page .notification-filters__right .ic input {
  visibility: hidden;
}
.notifications-page .notification-search {
  overflow: hidden;
  display: inline-flex;
  height: 40px;
  flex-flow: row nowrap;
  align-items: stretch;
  border: 0;
  border-radius: 9999px;
  box-shadow: 0 0 10px #ddd;
  background-color: #FFF;
}
@media (max-width: 664px) {
  .notifications-page .notification-search {
    height: 32px;
  }
}
.notifications-page .notification-search__input {
  display: block;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-left: 1px solid #eee;
  border-radius: 0;
  padding: 2px 0.75rem;
  font-size: 1rem;
}
.notifications-page .notification-search__button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
  background-image: url(../images/icon-search.svg);
  background-repeat: no-repeat;
  background-position: left 50% top 50%;
  padding: 3px 22px;
  margin: 0;
}
@media (max-width: 768px) {
  .notifications-page .notification-search__button {
    border-radius: 0;
  }
}
@media (min-width: 1216px) {
  .notifications-page .notification-search__button {
    padding: 7px 1.75rem 7px 2.875rem;
    background-position: left 12px top 50%;
    background-size: 32px 22px;
  }
}
.notifications-page .notification-search__button-text {
  display: none;
}
@media (min-width: 1216px) {
  .notifications-page .notification-search__button-text {
    display: inline;
  }
}

.collab-list {
  margin-top: 2rem;
}
.collab-list.active .collab-list__arrow {
  transform: rotate(180deg);
}
.collab-list.active .collab-list__header .collab-list__action {
  opacity: 1;
}
.collab-list.active .collab-list__content {
  display: block;
}
.collab-list__header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid #000;
}
.collab-list__header:hover .collab-list__action {
  opacity: 1;
}
.collab-list__name {
  margin-bottom: 0;
  padding: 0.875rem 0.625rem;
  cursor: pointer;
}
@media (max-width: 664px) {
  .collab-list__name {
    padding: 0.875rem 0rem;
    font-size: 16px;
  }
}
.collab-list__item {
  width: 100%;
  border-collapse: collapse;
}
.collab-list__item tr:hover .collab-list__action {
  opacity: 1;
}
.collab-list__item tr td {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  border-bottom: 1px solid #979797;
  background-color: #FFF;
  border-right: 0;
  border-left: 0;
}
.collab-list__item label {
  display: block;
  height: 100%;
}
.collab-list__actions {
  flex: 1 0 130px;
  margin-left: auto;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  text-align: right;
}
.collab-list__action {
  display: inline-block;
  padding: 0.25rem;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.16s ease-in-out;
}
.collab-list__action.on {
  opacity: 1;
}
.collab-list__arrow {
  transform: rotate(0);
  opacity: 1;
}
.collab-list__content {
  display: none;
  margin-bottom: 2.5rem;
}
.collab-list__item-logo {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
.collab-list__item-logo img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  max-width: inherit;
}
.collab-list__item-name {
  flex: 0 1 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
.collab-list__item-actions {
  flex: 1 0 160px;
  margin-left: auto;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  text-align: right;
}

.search-collaborators {
  min-height: 500px;
}
.search-collaborators__input {
  max-width: 350px;
}
.search-collaborators__results {
  max-width: 450px;
}
@media (max-width: 664px) {
  .search-collaborators__results {
    max-height: 300px;
    overflow-y: auto;
    padding-left: 1px;
    padding-right: 1px;
  }
}
.search-collaborators__results .search-item {
  padding: 1rem;
  cursor: pointer;
  transition: background-color 100ms ease;
}
.search-collaborators__results .search-item:nth-child(even) {
  background-color: white;
}
.search-collaborators__results .search-item:nth-child(odd) {
  background-color: whitesmoke;
}
.search-collaborators__results .search-item:hover {
  background-color: #c5c5c5;
}
.search-collaborators__results .search-item:focus-visible, .search-collaborators__results .search-item:active, .search-collaborators__results .search-item.active {
  outline: 1px solid #0080C3;
  border-radius: 0.2rem;
  background-color: #c5c5c5;
}

.search-list {
  display: none;
  overflow: auto;
  max-height: 150px;
  box-shadow: 0px 0px 4px 2px lightgrey;
  border-radius: 6px;
  z-index: 1000;
  background-color: white;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
.search-list__feedback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
}
.search-list__feedback--error::before {
  font-family: "Font Awesome 5 Free";
  content: "\f071";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
.search-list__item {
  padding: 0.3rem;
  transition: background-color 100ms ease-in-out;
}
.search-list__item:hover {
  background-color: lightblue;
  cursor: pointer;
}
.search-list__item:focus, .search-list__item:target, .search-list__item:active {
  background-color: #40b1d6;
}
.search-list__item.active {
  color: white;
  background-color: #187fa1;
}

.hint {
  color: #a1a1a1;
  font-size: small;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #0080C3;
  margin-left: auto;
  margin-right: auto;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.auth-notice {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border-radius: 0.2rem;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
@media (max-width: 1200px) {
  .auth-notice {
    margin-top: 1rem;
    padding: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.billing {
  margin: 0 auto;
}
.billing th {
  padding-bottom: 15px;
}
.billing__link {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  text-transform: uppercase;
  background-image: url(../images/download-bt.svg);
  background-repeat: no-repeat;
  background-position: center right;
  border: 1px solid #0080C3;
  border-radius: 999px;
  transition: transform 0.16s ease-in-out;
}
.billing__link:hover {
  background-image: url(../images/download-bt-on.svg);
  border-color: #FFC400;
  transform: scale(1.1);
}
.billing__col8 {
  width: 66.6666%;
}
.billing__col4 {
  width: 33.3333%;
}