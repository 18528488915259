.rating {
  font-style: normal;
  min-height: 29px;
  
  &__stars {
    margin-right: rem(6);
    line-height: rem(19);
    display: flex;
    align-items: center;
    & em {
      display: inline-block;
      margin-left: rem(5);
      font-size: rem(14);
      line-height: rem(19);
    }
  }

  &__star {
    display: inline-block;
    position: relative;
    top: 0px;
    width: rem(19);
    height: rem(19);
    margin-right: rem(2);
  }
}