.notifications-page {
  .notification {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    padding-top: rem(10);
    padding-bottom: rem(10);
    border-bottom: 1px solid #E4E4E4;
    font-size: rem(18);
    color: $black;

    &:first-child {
      border-top: 1px solid #E4E4E4;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    &__left {
      padding-right: rem(10);
      min-width: 40px;

      @media (max-width: 340px) {
        display: none;
      }
    }

    &__right {
      padding-right: rem(50);
    }

    &__title {
      margin-bottom: rem(4);
      font-size: rem(18);
      font-weight: 300;
    }

    &__name {
      margin-bottom: rem(6);
      font-size: rem(14);
    }

    &__time {
      position: absolute;
      top: rem(10);
      right: 0;
    }

    &__data {
      position: absolute;
      bottom: rem(10);
      right: 0;
      display: flex;
      align-items: center;
    }

    &__text {
      font-size: rem(14);
    }

    &__num {
      width: 22px;
      height: 22px;
      border-radius: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: rem(14);
      font-style: normal;
      font-weight: 700;

      color: $white;
      background-color: $blue;
    }

    &.unread {
      font-weight: 700;

      .notification__title {
        font-weight: inherit;
      }
    }
  }

  .notification-filters {
    margin-bottom: rem(20);

    @media (min-width: $tablet) {
      display: flex;
      align-items: center;
    }
    & .text-btn {
      line-height: 2.2rem;
    }

    &__left {
      margin-bottom: rem(20);

      @media (min-width: $tablet) {
        margin-bottom: rem(0);
        width: 50%;
      }
    }

    &__right {

      @media (min-width: $tablet) {
        width: 50%;
        text-align: right;
      }

      .button:not(:last-child) {
        margin-right: rem(10);

        @media (max-width: $mobile) {
          margin-right: rem(4);
        }
      }


      @media (max-width: $mobile) {
        .text-btn {
          min-height: 32px;
          padding: 4px 20px;
        }

        .ic {
          width: 32px;
          height: 32px;
        }
      }

      .ic {
        input {
          visibility: hidden;
        }
      }
    }
  }

  .notification-search {
    overflow: hidden;
    display: inline-flex;
    height: 40px;
    flex-flow: row nowrap;
    align-items: stretch;
    border: 0;
    border-radius: $button-radius;
    box-shadow: 0 0 10px #ddd;
    background-color: $white;

    @media (max-width: $mobile) {
      height: 32px;
    }

    &__input {
      display: block;
      border-top: 0;
      border-bottom: 0;
      border-right: 0;
      border-left: 1px solid #eee;
      border-radius: 0;
      padding: 2px rem(12);
      font-size: rem(16);
    }

    &__button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      cursor: pointer;
      background-image: url(../images/icon-search.svg);
      background-repeat: no-repeat;
      background-position: left 50% top 50%;
      padding: 3px 22px;
      margin: 0;

      @media (max-width: $tablet) {
        border-radius: 0;
      }

      @media (min-width: $widescreen) {
        padding: 7px rem(28) 7px rem(46);
        background-position: left 12px top 50%;
        background-size: 32px 22px;
      }
    }

    &__button-text {
      display: none;

      @media (min-width: $widescreen) {
        display: inline;
      }
    }
  }
}