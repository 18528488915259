.fancybox-content {
  padding: rem(50) rem(30);
}

.fancybox-container {
  .fancybox-slide {
    padding: rem(10);
  }
  .fancybox-content {
    max-width: 95%;
    max-height: 95%;
  }
}

.popup-iframe {
  padding-top: rem(30);
  padding-bottom: rem(30);
  background-color: #fff;
  max-width: 800px;

  &-close {
    position: fixed;
    top: rem(10);
    right: rem(10);
    padding: rem(10);
    cursor: pointer;
  }
}