.button {
  display: inline-block;
  border: 2px solid $blue;
  border-radius: $button-radius;
  padding: rem(6) rem(24);
  text-align: center;
  text-decoration: none;
  background: $blue;
  color: $white;
  font-size: $txtb;
  font-weight: 400;
  cursor: pointer;
  -webkit-appearance: none;


  transition:
    border-color .16s ease-in-out,
    background-color .16s ease-in-out;

  &:hover {
    background-color: $yellow;
    border-color: $yellow;
  }

  @media (max-width: $tablet) {
    font-size: rem(16);
  }

  &--slim {
    height: 40px;
    padding: rem(6) rem(20);

    @media (max-width: $tablet) {
      height: 36px;
      padding: rem(6) rem(16);
      font-size: rem(14);
    }
  }

  &:disabled{
    opacity: 0.5;
  }
}

.support{
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 1000;

    display: inline-block;
    /* border: 2px solid $blue; */
    border-radius: $button-radius;
    /* padding: rem(6) rem(12); */
    text-align: center;
    text-decoration: none;
    /* background: $blue; */
    color: $white;
    font-size: $txtb;
    font-weight: 400;
    cursor: pointer;
    -webkit-appearance: none;

    img{
        width: 70px;
    }
    /* transition:
        border-color .16s ease-in-out,
        background-color .16s ease-in-out;

    &:hover {
        background-color: $yellow;
        border-color: $yellow;
    } */
    @media (max-width: $tablet) {
        font-size: rem(16);
      }

      &--slim {
        height: 40px;
        padding: rem(6) rem(20);

        @media (max-width: $tablet) {
          height: 36px;
          padding: rem(6) rem(16);
          font-size: rem(14);
        }
      }

      &:disabled{
        opacity: 0.5;
      }
}

.text-btn {
  display: inline-block;
  min-height: $iconw;
  margin: 3px 4px;
  border-radius: 30px;
  padding: 9px 20px;
  vertical-align: bottom;
  text-align: center;
  font-size: $txtb;
  line-height: rem(18);
  font-weight: 400;
  background-color: $blue;
  color: $white;
  cursor: pointer;
  transition:
    // transform .16s ease-in-out,
    background-color .16s ease-in-out;

  @media (max-width: 1400px) {
    padding: 9px 10px;
  }

  &.on {
    background-color: $yellow;
  }

  &:hover {
    background-color: $yellow;
    // transform: scale(1.05);
  }
}

.ic--hide-ad-plus{
    height: 53px !important;
    border: none !important;
}

.ic--hide-ad {
    width: 42px;
    height: 48px;
    background-image: url("../images/btns/hide-ad.svg");
  }

.button-offer{
    width: 20px;
    margin-right: 5px;
}

.alert-img{
    width: 18px;
    margin-right: 5px;
    margin-bottom:5px;
}

.alert-text{
    font-size: 18px;
}

.alert-container{
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 1rem;
    & p {
      margin-bottom: 0;
    }
}


.button-docs{
    margin: 10px 0px;
}

.button-docs a{
    color: black;
}

.ic {
  display: inline-block;
  width: $iconw;
  height: $iconw;
  margin: 3px 4px;
  vertical-align: bottom;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  cursor: pointer;
  border-radius: 999px;
  transition: transform .16s ease-in-out;
  border: 1px solid $blue;

  &.on, &:hover {
    transform: scale(1.05);
    border: 1px solid $yellow;
  }

  &--destaca {
    background-image: url('../images/btns/destaca.svg');
    &.on, &:hover {
      background-image: url('../images/btns/destaca-on.svg');
    }
  }
  &--tag {
    background-image: url('../images/notifications/tag.svg');
    &.on, &:hover {
      background-image: url('../images/notifications/tag-on.svg');
    }
  }
  &--deal {
    background-image: url('../images/notifications/deal.svg');
    &.on, &:hover {
      background-image: url('../images/notifications/deal-on.svg');
    }
  }
  &--chat {
    background-image: url('../images/notifications/chat.svg');
    &.on, &:hover {
      background-image: url('../images/notifications/chat-on.svg');
    }
  }
  &--send {
    background-image: url('../images/notifications/send.svg');
    &.on, &:hover {
      background-image: url('../images/notifications/send-on.svg');
    }
  }

  &--alert {
    background-image: url('../images/btns/alert.svg');
    &.on, &:hover {
      background-image: url('../images/btns/alert-on.svg');
    }
  }
  &--user {
    background-image: url('../images/btns/user.svg');
    border-radius: 100%;
    background-size: cover;
    // border: 1px solid $blue;
    &.on, &:hover {
      background-image: url('../images/btns/user-on.svg');
    }
  }
  &--info {
    background-image: url('../images/btns/info.svg');
    /* transform: rotate(180deg); */
    border-radius: 100%;
    background-size: cover;
    &.on, &:hover {
      background-image: url('../images/btns/info-on.svg');
    }
  }
  &--mail {
    background-image: url('../images/btns/mail.svg');
    &.on, &:hover {
      background-image: url('../images/btns/mail-on.svg');
    }
  }
  &--share {
    background-image: url('../images/btns/share.svg');
    &.on, &:hover {
      background-image: url('../images/btns/share-on.svg');
    }
  }
  &--more {
    background-image: url('../images/btns/more.svg');
    &.on, &:hover {
      background-image: url('../images/btns/more-on.svg');
    }
  }
  &--arrow-r {
    background-image: url('../images/btns/arrow-r.svg');
    &.on, &:hover {
      background-image: url('../images/btns/arrow-r-on.svg');
    }
  }
  &--businesses {
    background-image: url('../images/btns/businesses.svg');
    &.on, &:hover {
      background-image: url('../images/btns/businesses-on.svg');
    }
  }
  &--fab {
    background-image: url('../images/btns/ico-edit.svg');
    &.on, &:hover {
      background-image: url('../images/btns/ico-edit-on.svg');
    }
  }
  &--businesses-send {
    width: $iconw;
    height: $iconw;
    background-image: url('../images/btns/message.svg');
    &.on, &:hover {
      background-image: url('../images/btns/message-on.svg');
    }
  }
  &--businesses-fav {
    width: $iconw;
    height: $iconw;
    background-image: url('../images/btns/save.svg');
    &.on, &:hover {
      background-image: url('../images/btns/save-on.svg');
    }

    &.active{
        background-image: url('../images/btns/save-on.svg');
    }
  }
  &--hide-ad {
    width: $iconw;
    height: $iconw;
    background-image: url('../images/btns/hide-ad.svg');
    border-radius: 0;
    &.on, &:hover {
      background-image: url('../images/btns/hide-ad-on.svg');
    }
  }
  &--trash {
    width: $iconw;
    height: $iconw;
    background-image: url('../images/btns/trash_active.svg');
    &.on, &:hover {
      background-image: url('../images/btns/trash_over.svg');
    }
  }
}
