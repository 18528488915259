.form {

  hr {
    margin-bottom: 1rem;
  }
  .title {
    margin-bottom: 0;
    font-family: $font-attila;
    color: $blue;
    font-weight: 400;
  }
  &__field {
    position: relative;
    margin-bottom: rem(20);

    &.error{
     .input{
        border-color: red;
      }
    }

    &.expanded {
      z-index: 9999;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 800px;
      max-width: 90%;
      max-height: 80%;
      margin-bottom: 0;
      box-shadow: 0 8px 20px rgba(0, 0, 0, .6);
      padding: 20px;
      background-color: $white;

      .textarea {
        height: 380px;
      }

      .form__expand {
        top: 10px;
        right: 10px;
      }
    }
    &__cl {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__label {
    display: block;
    margin-bottom: rem(10);

  }
  &__label-info {
    margin-left: 6px;
    cursor: pointer;
    vertical-align: middle;
  }

  &__expand {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 10px;
    cursor: pointer;
  }

  &__button {
    margin-top: rem(10);
    width: 200px;
  }
  .column {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.showpass {
  padding-left: 25px;
  background-image: url(../images/icon-show-pass.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: rem(20) auto;
  color: $blue;
  font-weight: bold;
  margin-bottom: rem(10);
}

.input {
  width: 100%;
  height: 36px;
  border-radius: 3px;
  border: 1px solid $blue;
  padding: rem(3) rem(8);
  background: $white;
  font-size: 16px;

  &[type='file'] {
    padding: rem(6);
  }

  &:is(input){
    &:not(:focus):not(:placeholder-shown):invalid{
      border-color: #B00020;

      & + .input__error{
        display: block;
      }
    }

    // &.validate:not(:focus):not(:placeholder-shown):valid{
    //   border-color: #4caf50;
    // }
  }

  &__error{
    display: none;
    color: #B00020;
    font-size: 0.9rem;
    margin-top: 0.5rem;

    .material-icons{
      color: #B00020;
      margin-right: 0.3rem;
    }
  }

}

.textarea {
  display: block;
  width: 100%;
  border-radius: 3px;
  border: 1px solid $blue;
  padding: rem(3) rem(8);
  background: $white;
  font-size: 16px;
}

.opt-wrapper {
  @media (max-width: $tablet) {
    display: block;
  }
}

.str-text {
  font-style: normal;
  color: #4a4a4a;
}

.tag-businesses {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: rem(16);
  &:hover {
    text-decoration: underline;

    .ic--businesses-send {
      background-image: url(../images/btns/businesses-send-on.svg);
      transform: scale(1.05);
    }
  }
}

.hide-businesses {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: rem(16);
  &:hover {
    text-decoration: underline;

    .ic--hide-ad {
      background-image: url(../images/btns/hide-ad-on.svg);
      transform: scale(1.05);
    }
  }
}

::placeholder {
  font-style: normal;
  color: #4a4a4a;
  opacity: 1;
}
:-ms-input-placeholder {
  font-style: normal;
  color: #4a4a4a;
}
::-ms-input-placeholder {
  font-style: normal;
  color: #4a4a4a;
}

.event-notice-new {
  color: green;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding: 1rem;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
