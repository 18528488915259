@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@font-face {
	font-family: 'Attila_Sans';
	src: url('fonts/AttilaSansClassicTrial-Regular.eot');
	src: local(''), url('fonts/AttilaSansClassicTrial-Regular.woff') format('woff'), url('fonts/AttilaSansClassicTrial-Regular.otf') format('truetype'), url('fonts/AttilaSansClassicTrial-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

html,
body {
  box-sizing: border-box;
  line-height: 1.4;
  font-size: $font-size + px;
  font-family: $font-montserrat;
  color: $black;
}

body {
  margin: 0;
  //background-color: rgba(216, 216, 216, 0.2);
}

* {
  box-sizing: inherit;
  line-height: inherit;
  font-family: inherit;
  font-style: inherit;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.title {
  font-weight: 700;
  font-size: rem(30);
  font-family: $font-attila;
  color: $blue;
  font-weight: 400;
}


.title-2 {
  font-size: rem(24);
  font-family: $font-attila;
  color: $blue;
  font-weight: 400;
}

a {
  color: $blue;
  text-decoration: none;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
}

hr {
  border: 0;
  border-bottom: 1px solid #E4E4E4;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  border-radius: 100px;
  background-color: #babac0;
  background-clip: content-box;

  &:hover {
      opacity: 0.4;
  }
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

input[type="checkbox"]{
  vertical-align: middle;
  width: 1em;
  height: 1em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

input[type="checkbox"]:checked {
  background-color: $blue;
  border-color: $blue;
}

.form-check-input, .form-check-input[type=radio]  {
  width: 1em;
  height: 1em;
  margin-top: 0em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:checked, .form-check-input[type=radio]:checked {
  background-color: $blue;
  border-color: $blue;
}
.form-check-input:checked[type=radio] {
  background-image: url('');
}


input, input[type="text"],input[type="password"],input[type="email"],input[type="button"],select,textarea, button{-webkit-appearance:none;-moz-appearance:none;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0}



.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.container {
  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;
  padding-right: rem(40);
  padding-left: rem(40);
  @media (max-width: $tablet) {
    padding-right: rem(20);
    padding-left: rem(20);
  }

  &--box {
    max-width: 900px;
    margin: rem(20) rem(20);
    padding: rem(20);
    background-color: $white;

    @media (min-width: $tablet) {
      margin: rem(60) auto;
      padding: rem(50);
    }
  }
}

.content {
  // padding-top: $header;

  @media (max-width: $tablet) {
    padding-top: $header-tablet;
    padding-bottom: 66px;
  }

  @media (max-width: $mobile) {
    padding-top: 0px;
    padding-bottom: 60px;
  }

  /* @media (max-width: $mobile) {
    padding-top: $header-mobile;
    padding-bottom: 60px;
  } */
}

.loading-screen {
  z-index: 800;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;

  display: none;
}

.login-form {
  width: 420px;
  max-width: 95%;
}

.color-green {
  color: $green-ok;
}

.color-grey {
    background-color: rgb(160, 160, 160);
    opacity: 0.6;
}

.text-md {
  font-size: rem(14);
}

.text-sm {
  font-size: rem(12);
}

.read-more {
  cursor: pointer;
  display: inline-block;
  padding-top: rem(10);
  padding-bottom: rem(10);

  img {
    width: 20px;
    height: 20px;
  }
}

.text-expiration {
  display: inline-block;
  text-align: center;
  //margin-top: rem(10);
  color: $blue;
  // font-weight: 700;
  // font-size: rem(22);
  font-size: rem(13);
  text-transform: uppercase;
}

.popup-iframe-close{
  z-index: 1000;
}

.event-notice{
  border-radius: 0.4rem;
  background-color: rgba(lightgreen, 0.3);
  color: green;
  border: 1px solid mediumseagreen;
  padding: 1rem;

  &--error{
    background-color: rgba(red, 0.25);
    border: 1px solid red;
    color: firebrick;

    ul {
      margin: 0;
    }
  }
}

.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
} //default
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
.material-icons{
  vertical-align: sub;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.rating1 {
    position: relative;
    margin: auto;
    width: 200px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3em;
    padding: 5px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 0 2px #b3acac;
 }

 .rating__result1 {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-10px) translateX(-5px);
    z-index: -9;
    font: 3em Arial, Helvetica, sans-serif;
    color: #ebebeb8e;
    pointer-events: none;
 }

 .rating__star1 {
    font-size: 1.3em;
    cursor: pointer;
    color: #dabd18b2;
    transition: filter linear .3s;
 }

 .rating__star:hover {
    filter: drop-shadow(1px 1px 4px gold);
 }

.column.is-4-middle {
  @media screen and (min-width: 1044px) and (max-width: 1306px) {
      flex: 0 0 auto;
      width: 33%;
  }
}

.profile__content .column.is-3-desktop {
  @media screen  and (min-width: 768px) and (max-width: 1385px) {
    flex: 0 0 auto;
    width: 50%;
  }
  @media screen and (min-width: 1386px) {
    flex: 0 0 auto;
    width: 33%;
  }
}
