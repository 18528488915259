.mySwiper {
  max-width: 700px;
  height: 100%;
}

.swiper-slide {
background-color: grey;
height: auto !important;
text-align: center;
}
.swiper-content {
height: 100% !important
}
.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  opacity: 1;
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-bullet-active {
  background: rgba(255, 255, 255, 1);
}
.swiper-slide-bg {
  width: 100%;
  padding-bottom: 70%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}