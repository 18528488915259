.filters-box {
  display: flex;
  flex-flow: row wrap;
}
.filters-tabs {
  flex: 0 0 50%;
  padding-top: rem(20);
  padding-bottom: rem(20);
  font-size: rem(55);
  line-height: rem(55);
  color: $blue;
  font-family: $font-attila;
  font-weight: 100;
  @media (max-width: $mobile) {
    flex: 0 0 100%;
    font-size: rem(28);
  }
  &__link {
    font-family: $font-attila;
    font-size: rem(55);
    line-height: rem(55);
    font-weight: 400;
    position: relative;
    cursor: pointer;
    @media (max-width: $mobile) {
      font-size: rem(28);
    }
    &--select span {
      opacity: 0.2;
    }
    &:first-child {
      padding-right: rem(24);
      margin-right: rem(24);
      &::after {
        content: "";
        border-right: 3px solid $blue;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -20px;
        width: 1px;
        height: 50px;
      }
    }
    // &:first-child {
    //   padding-right: rem(24);
    //   border-right: 1px solid $blue;
    //   margin-right: rem(24);
    // }
  }
  // & em {
  //   display: inline-block;
  //   width: 1px;
  //   height: 50px;
  //   border-right: 3px solid $blue;
  // }
}

.filters {
  flex: 0 0 50%;
  position: relative;
  font-size: rem(14);
  padding-top: rem(40);
  padding-bottom: rem(20);

  display: flex;
  justify-content: right;
  @media (max-width: $mobile) {
    justify-content: left;
  }

  &__main {
    @media (max-width: $desktop) {
      padding-right: 0;
    }
  }

  &__tgl {
    padding: rem(4) rem(10);
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    font-weight: 400;
    font-size: rem(14);
    background: $white;
    cursor: pointer;
    color: $black;

    &:hover {
      color: $black;
      background-color: $white;
    }

    @media (min-width: $desktop) {
      display: none;
    }
  }

  &__content {
    display: none;

    @media (max-width: $desktop) {
      padding-top: rem(20);
    }

    @media (min-width: $desktop) {
      display: flex !important;
      align-items: center;
    }

    &.active {
      display: block;
    }
  }

  &__info-btn {
    cursor: pointer;
    padding: 4px 6px;
    margin-left: 5px;
  }

  &__input {
    margin-left: 10px;
    width: auto;
    height: $iconw;
    padding: rem(6) rem(10);
    border: 1px solid  $blue;
    border-radius: 9999rem !important;
    font-size: $txtb;
    background-color: $white;
    -webkit-background-color: $white;
    color: $blue;
    background-image: url(../images/icon-search-blue.svg);
    background-repeat: no-repeat;
    background-position: right 15px top 50%;
    background-size: 16px auto;
    @media (max-width: $mobile) {
        height: 32px;
    }


    &:focus-visible {
      outline: none !important;
    }
  }

  &__select {
    height: $iconw;
    padding: rem(6) rem(10);
    border: 1px solid $blue;
    border-radius: 9999rem;
    font-size: $txtb;
    background: $white;
    -webkit-background: $white;
    cursor: pointer;
    color: $blue;
    @media (max-width: $mobile) {
        height: 32px;
    }
    @media only screen and (min-width: 1551px) {
      width: 260px;
    }

    @media only screen and (max-width: 1550px) {
      width: 180px;
    }

  }

  &__geoloc {
    margin-left: rem(40);
    display: inline-flex;
    align-items: center;

    @media (max-width: $mobile) {
      display: block;
      margin-right: 0;
    }

    @media (max-width: $desktop) {
      margin-bottom: 10px;
    }

    input {
      margin: 0;
    }
  }

  &__geoloc-label {
    margin-left: rem(6);
    margin-right: rem(16);
    font-size: $txtb;
  }

  &__sort-label {
    margin-right: rem(12);
    font-size: $txtb;

    @media (max-width: 380px) {
      display: none;
    }
  }

  &__right {
    text-align: right;
    @media (max-width: 380px) {
      width: 160px;
    }
  }

  &__btn {
    height: $iconw;
    padding: rem(6) rem(10);
    border: 1px solid $blue;
    border-radius: 9999rem;
    background: $white;
    cursor: pointer;
    color: $blue;
    font-size: $txtb;

    &:hover {
      color: $white;
      background-color: $blue;
    }

    &--geoloc {
      padding-left: rem(28);
      background-image: url(../images/icon-geolocation.svg);
      background-position: left 4px top 50%;
      background-size: 22px 18px;
      background-repeat: no-repeat;
    }
  }
}

.button-group {
  display: inline-flex;
  flex-flow: row nowrap;

  button {
    border-radius: 0;
    display: block;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:not(:first-child) {
      // border-left: 0;
      margin-left: -2px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.tooltip-filters {
  width: 100%;
  font-size: 14px;
}

// .filters {
//   font-size: rem(14);
//   font-style: italic;
//   align-items: center;
//   margin-bottom: 0 !important;

//   @media (min-width: $desktop) {
//     padding-top: rem(30);
//     padding-bottom: rem(10);
//   }

//   &__tgl {
//     padding: rem(4) rem(10);
//     border: 1px solid #E1E1E1;
//     border-radius: 8px;
//     font-weight: 400;
//     background: $white;
//     font-size: rem(14);
//     cursor: pointer;
//     color: $black;

//     &:hover {
//       color: $black;
//       background-color: $white;
//     }

//     @media (min-width: $desktop) {
//       display: none;
//     }
//   }

//   &__content {
//     // padding-bottom: rem(20);
//     display: none;

//     @media (min-width: $desktop) {
//       display: block;
//     }

//     &.active {
//       display: block;
//     }
//   }

//   &__geoloc {
//     margin-right: rem(40);
//     display: inline-flex;
//     align-items: center;

//     @media (max-width: $mobile) {
//       display: block;
//       margin-right: 0;
//     }

//     @media (max-width: $desktop) {
//       margin-bottom: 10px;
//     }

//     input {
//       margin: 0;
//     }
//   }

//   &__geoloc-label {
//     margin-left: rem(6);
//     margin-right: rem(16);
//   }

//   &__sort-label {
//     margin-right: rem(12);
//   }

//   &__right {
//     @media (min-width: $desktop) {
//       text-align: right;
//       width: 300px;
//     }
//   }

//   &__btn {
//     padding: rem(4) rem(10);
//     border: 1px solid #E1E1E1;
//     border-radius: 8px;
//     background: $white;
//     cursor: pointer;

//     &:hover {
//       color: $white;
//       background-color: $blue;
//     }

//     &--geoloc {
//       padding-left: rem(28);
//       background-image: url(../images/icon-geolocation.svg);
//       background-position: left 4px top 50%;
//       background-size: 22px 18px;
//       background-repeat: no-repeat;
//     }
//   }

//   &__select {
//     padding: rem(4) rem(10);
//     border: 1px solid #E1E1E1;
//     border-radius: 8px;
//     background: $white;
//     cursor: pointer;
//   }
// }
