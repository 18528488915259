.offers {


  .container--box {
    max-width: 1200px;
  }

}

.offers-meta {
  margin-top: rem(10);
  margin-bottom: rem(10);

  @media (min-width: $tablet) {
    text-align: right;

    .ad__info-item {
      display: inline-block;
      margin-right: rem(20);
    }
  }
}

.is-job {
  position: relative;
  padding-bottom: 120px;
  @media (max-width: $mobile) {
    padding-bottom: 170px;
  }
}