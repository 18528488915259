.business-card {
  // padding: rem(10) 0 rem(20);
  // font-size: rem(14);
  /*background-color: $white;*/

  &__bg {
    display: block;
    margin-bottom: rem(30);
    position: relative;
    padding-bottom: 56%;
    background-position: 50%;
    background-size: cover;
    background-color: $blue;
    // padding: rem(20) rem(20) 0;
    // margin-right: rem(20);
    // margin-left: rem(20);
  }
  &__logo {
    // width: 100%;
    // height: 150px;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    position: absolute;
    bottom: -20px;
    left: 16px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    border: 4px solid $white;

    background-color: white;

    // text-align:  center;
    overflow: hidden;
    // border-bottom: 1px solid $blue;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // max-height: 100px;
      // max-width: rem(230);
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .location {
    margin-top: 15px;
  }

  &__content {
    // padding-right: rem(20);
    // padding-left: rem(20);
    padding-right: rem(20);
    padding-left: rem(20);
    position: relative;
  }
  &__bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-right: rem(20);
    padding-left: rem(20);
    height: 155px;
  }

  &__name {
    margin-bottom: rem(6);
    font-size: rem(20);
    color: $black;
    text-transform: uppercase;

    > a {
      //color: inherit;
      font-weight: 500;
    }
    .profile__badge {
      top: 3px;
      img {
        width: 20px;
        margin-left: 5px;
      }
    }
  }

  &__intro {
    margin-bottom: rem(14);
  }

  &__stats {
    margin-bottom: rem(14);
    font-size: rem(13);
  }
}

.profile-content {
  .fab{
    position: fixed;
    bottom: 1rem;
    right: 1.5rem;
    border-radius: 100%;
    background-color: $blue;
    padding: 1rem;
    z-index: 3000;
    cursor: pointer;
    transition: transform 120ms ease;
    box-shadow: 0px 4px 5px rgb(0 0 0 / 20%);
    width: 63px;
    height: 63px;

    img{
      width: 100%;
    }

    &:hover, &:focus-visible{
      transform: scale(1.1);
    }
  }

  .hover-darken {
    cursor: pointer;
    transition: filter 200ms ease;

    &:hover{
      filter: brightness(50%);
    }
  }

}

.profile {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  min-height: calc(100vh - $header);

  &.container {
    max-width: 1600px;
    padding-top: rem(40);
  }

  &__main {
    flex: 0 1 100%;
    width: 100%;
    //background-color: rgba(216, 216, 216, 0.2);
    transition: all 300ms ease-in-out;
    border-top-left-radius: rem(7);
    overflow: hidden;
  }

  &__side {
    position: relative;
    flex: 0 0 360px;
    width: 360px;
    padding: rem(40) rem(30);
    background-color: #EBEBEB;
    transition: all 300ms ease-in-out;
    border-top-right-radius: rem(7);

    @media (max-width: $widescreen) {
      flex: 0 0 260px;
      width: 260px;
      padding: rem(40) rem(20);
    }

    @media (max-width: $desktop) {
      z-index: 1100;
      position: fixed;
      top: $header;
      right: 0;
      bottom: 0;
      transform: translateX(360px);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      box-shadow: 0 10px 10px #333;
      font-size: rem(14);
    }

    @media (max-width: $tablet) {
      top: $header-tablet;
    }

    @media (max-width: $mobile) {
      top: $header-mobile;
    }
  }

  &__side-content {
    .rating {
      font-size: rem(20);
      margin-bottom: rem(6);

      &__star {
        width: 22px;
        height: 22px;
      }
    }
    .business__location {
      font-size: rem(16);
      margin-bottom: rem(6);

      a {
        color: $black;
        // word-break: break-all;
      }
    }

    .business__name {
      margin-bottom: 0;
      font-size: rem(16);
      font-weight: 400;
      color: inherit;
    }

    .business__services{
      ul{
        list-style: none;
        padding-left: 0;
        li{
          font-size: 1rem;
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 20px;
    font-size: rem(14);
    cursor: pointer;

    @media (min-width: $desktop) {
      display: none;
    }
  }

  &__side-arrow {
    position: absolute;
    top: 80px;
    left: -60px;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-right-width: 40px;
    border-right-color: #EBEBEB;

    @media (max-width: $widescreen) {
      display: none;
    }
  }

  &__header {
    position: relative;
    padding: 240px 20px 20px;
    background-color: $blue;

    @media (max-width: $desktop) {
      padding: 180px 0 10px;
    }

    @media (max-width: $mobile) {
      padding: 100px 0 10px;
    }
    .container {
      padding-right: 10px;
    }
    .is-narrow {
      width: 200px;
      @media (max-width: $desktop) {
        width: 100px;
      }
    }

    .ic {
      width: $iconw;
      height: $iconw;
    }
  }

  &__image {
    overflow: hidden;
    position: absolute;
    bottom: -20px;
    width: 210px;
    height: 210px;
    border: 5px solid #f7f7f7;
    border-radius: 100%;
    background-color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $desktop) {
      width: 100px;
      height: 100px;
    }


    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    margin-top: rem(80);
    @media (max-width: $tablet) {
      margin-top: rem(20);
    }
    & .container {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__name {
    display: inline-block;
    margin-bottom: rem(4);
    margin-right: rem(6);
    font-family: $font-attila;
    font-size: rem(34);
    line-height: rem(34);
    font-weight: normal;
    color: $blue;
    @media (max-width: $tablet) {
    }
  }

  &__tit {
    font-size: rem(14);
    font-weight: normal;
    color: $blue;
    margin-bottom: rem(2);
    &--accordion {
      cursor: pointer;
      width: fit-content;
      padding-right: rem(22);
      background-image: url("../images/icon-accordion.svg");
      background-repeat: no-repeat;
      background-position: 97% center;
      background-size: rem(13) auto;
    }
  }

  &__badge {
    display: inline-block;
    position: relative;
    top: 4px;
    width: rem(37);
    height: rem(37);
  }

  &__collab {
    font-size: rem(20);
    margin-bottom: rem(10);
  }

  &__text {
    margin-top: rem(20);
    margin-bottom: rem(30);
    font-size: rem(14);
    max-height: 325px;
    overflow-y: auto;
  }

  &__text-hidden {
    margin-top: rem(16);
    display: none;
  }

  &__opinions-title {
    font-size: rem(20);
    margin-bottom: rem(6);
  }

  &__images {
    margin-bottom: 10px;

    img {
      display: block;
      width: 100%;
    }
    .columns {
      margin: 0;
    }
    .column {
      padding: 3px;
    }
  }


  .tabs-select {
    padding: 0 rem(20);
    margin-bottom: rem(10);

    @media (min-width: $tablet + 1px) {
      display: none;
    }

    @media (max-width: $tablet) {
      padding: rem(20) rem(20) 0;
    }

    @media (max-width: $mobile) {
      padding: rem(20) 0 0;
    }

    select {
      margin-left: 10px;
    }
  }
  .tabs {
    @media (max-width: $tablet) {
     // display: none;
    }
  }
  .tabs-content {
    @media (max-width: $tablet) {
      padding-top: 0;
    }
  }
  &__button {
    margin-bottom: 1.5rem;
  }
}

.opinion {
  margin-top: rem(16);
  &__date {
    margin-bottom: rem(12);
    font-size: rem(12);
  }
  &__text {
    font-size: rem(14);
  }

  .rating {
    margin-bottom: 0;
    &__star {
      width: 18px;
      height: 18px;
    }
  }
}

.profile-sidebtn {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  transition: none;

  &.active {
    transform: rotate(-180deg);
  }

  @media (max-width: $desktop) {
    top: 10px;
    right: 20px;
  }
}

.profile.collapsed {
  overflow: hidden;

  .profile__main {
    flex: 0 0 100%;
  }
  .profile__side {
    @media (min-width: $desktop) {
      flex: 0 0 0%;
      overflow: hidden;
      .profile__side-content {
        opacity: 0;
      }
    }
    @media (max-width: $desktop) {
      transform: translateX(0);
    }
  }
  .is-4-desktop {
    @media (min-width: $desktop) {
      width: 25%;
    }
  }
}


.profile-filters {

  @media (min-width: $tablet) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(30);
  }

  &__filter {
    margin-bottom: rem(10);

    select {
      margin-left: rem(10);
    }
  }
}

.services-list{
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0;

  &__item{
    margin-block: 0.3rem;
    transition: all 100ms ease;
    width: fit-content;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    .handle{
      width: 25px;
      height: 25px;
      background-image: url("../images/icon-drag_handle.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: move;
    }

    .delete{
      width: 20px;
      height: 20px;
      background-image: url("../images/action/trash.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    &.sortable-chosen{
      // transform: scale(1.03);
      // box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.1);
    }

    // &.ghost{
    //   transform: scale(1.3);
    // }
  }



}
.show__badge {
  display: inline-block;
  position: relative;
  top: 0;
  width: rem(20);
  height: rem(20);
  img {
    width: rem(20);
    margin-left: 5px;
  }
}
