$tab-border-color: #e1e1e1;

.tabs {
  // border-bottom: 1px solid $tab-border-color;
  // padding-right: rem(20);
  // padding-left: rem(20);

  &__tab {
    cursor: pointer;
    display: inline-block;
    padding: rem(8) rem(24) rem(8) rem(0);
    font-family: "Attila_Sans", sans-serif;
    font-weight: 400;
    font-size: rem(32);
    color: $blue;
    position: relative;
    opacity: 0.2;

    @media (max-width: $mobile) {
        font-size: rem(22);
    }

    &:hover {
     opacity: 1;
    }

    &.active {
      opacity: 1;
    }
    &.active span {
      opacity: 1;
    }
    &:first-child {
      margin-right: rem(24);
      &::after {
        content: "";
        border-right: 1px solid $blue;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -20px;
        width: 1px;
        height: 40px;
      }
    }
  }

  &--modal{
    padding-left: 0;
    & .tabs__tab{
      font-size: rem(18);
    }
  }
}

.tabs-content {
  padding: rem(30) rem(20) rem(40) 0;

  @media (max-width: $mobile) {
    padding: rem(20) rem(0);
  }
}

.tab {
  display: none;

  &.active {
    display: block;
  }
}
